import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Avatar, Box, Button, Card, Grid} from '@mui/material'
import {FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {$authHost} from '../../http'
import LoadingSpinner from '../modals/loadingSpinner'
import {useActions} from '../../hook/useActions'
import Chip from '@mui/material/Chip'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import IconButton from '@mui/material/IconButton'
import AlertMessage from '../Alert/AlertMessage'
import {useSelector} from 'react-redux'
import {acceptFileType} from '../../constants'
import Stack from "@mui/material/Stack";
import CustomTextField from "../Fields/CustomTextField";

const UserData = () => {

  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [uploadedAvatar, setUploadedAvatar] = useState(null)
  const [uploadedAvatarLabel, setUploadedAvatarLabel] = useState(null)
  const {
    last_name,
    email,
    avatar,
    name,
  } = useSelector(state => state.authReducer)

  const {
    setErrorAlertMessage,
    updateUser,
  } = useActions()

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      email,
      name,
      last_name,
    },
  })
  const {
    handleSubmit,
    formState: {isDirty, isSubmitSuccessful},
    reset,
  } = methods

  const inputRef = useRef(null)

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (fileObj && (acceptFileType.indexOf(fileObj.type) !== -1) && (fileObj.size / (1024 ** 2) < 3.6)) {
      const FR = new FileReader()
      FR.addEventListener('load', function (evt) {
        setUploadedAvatar(evt.target.result)
      })
      FR.readAsDataURL(event.target.files[0])
      setUploadedAvatarLabel(fileObj.name)
    } else {
      setErrorAlertMessage(t('src.pages.AccountPage.UserData.extension'))
    }
  }

  const cleanInput = () => {
    setUploadedAvatar(null)
    setUploadedAvatarLabel(null)
  }

  const loadData = useCallback(async () => {
    try {
      // const response = await $authHost.get(`/user/find_one/${userId}`)
      const response = await $authHost.get(`/user/me`)
      const {last_name, email, avatar, name, roles, status, locked_flags, groups} = response.data
      updateUser({
        last_name,
        email,
        avatar,
        name,
        roles,
        status,
        locked_flags,
        groups,
      })
    } catch (e) {
    }
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    let update_data = data
    // update_data['id'] = userId
    if (uploadedAvatar !== null) {
      update_data['avatar'] = uploadedAvatar
    } else {
      update_data['avatar'] = avatar
    }
    try {
      await $authHost.patch(`/user/me/update`, update_data)
      loadData()
    } catch (e) {
    } finally {
      setLoading(false)
      cleanInput()
      // document.getElementById('button_send').disabled = true
    }
  }

  useEffect(() => {
    loadData() // Обновление данных пользователя при входе на страницу
  }, [])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        email,
        name,
        last_name,
      })
    }
  }, [email, name, last_name])

  return (
    <Box sx={{flexDirection: 'column', px: 0, py: 4}}>
      {loading && <LoadingSpinner/>}
      <AlertMessage/>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{width: '100%', p: 4}}>
            <Grid
              container
              // xs={12}
              sx={{mb: 2}}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{mb: 2, pr: 2}}>
                <div className="containerImg">
                  <Avatar
                    id="img"
                    // className="image"
                    src={uploadedAvatar || avatar}
                    alt="photoURL"
                    sx={{width: 200, height: 200, mb: 2}}
                  />
                  <input
                    id="avatar"
                    style={{display: 'none'}}
                    ref={inputRef}
                    type="file"
                    onChange={handleFileChange}
                  />
                  <div className="overlay" onClick={() => {
                    inputRef.current.click()
                  }}>
                    <IconButton aria-label="delete" className="icon">
                      <DriveFolderUploadIcon fontSize="inherit"/>
                    </IconButton>
                  </div>
                </div>
                {
                  uploadedAvatarLabel &&
                  <Chip label={uploadedAvatarLabel} variant="outlined" onDelete={cleanInput}/>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <CustomTextField
                  name='email'
                  label={t('src.pages.AccountPage.UserData.email')}
                  required
                  rules={{
                    required: t('main.another.requiredField'),
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: t('src.pages.AccountPage.UserData.wrongEmail'),
                    },
                  }}
                />
                <CustomTextField
                  name='name'
                  label={t('src.pages.AccountPage.UserData.name')}
                  required
                />
                <CustomTextField
                  name='last_name'
                  label={t('src.pages.AccountPage.UserData.last_name')}
                  required
                />
              </Grid>
            </Grid>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Button
                id="button_send"
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isDirty && !uploadedAvatar}
              >
                {t('main.another.submitButton', 'Сохранить изменения')}
              </Button>
              <Button
                variant='text'
                onClick={() => reset()}
                disabled={!isDirty}
              >
                {t('main.another.resetButton', 'Сбросить')}
              </Button>
            </Stack>
          </Card>
        </form>
      </FormProvider>
    </Box>
  )
}

export default UserData;