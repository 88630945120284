import { alpha, styled } from '@mui/material/styles'
import { APP_BAR_DESKTOP, APP_BAR_MOBILE, HEADER_DESKTOP, HEADER_MOBILE } from '../constants'
import {AppBar, Autocomplete, Box, Grid, ListItemButton, Paper, Toolbar} from '@mui/material'
import { Link } from 'react-router-dom'
import Chip from "@mui/material/Chip";
import React from "react";
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
export const StyledRoot = styled('div')({
  backgroundColor: 'background.default',
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
})

export const Main = styled('div')(({ theme }) => ({
  backgroundColor: 'background.default',
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 5,
  paddingBottom: 0,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 10,
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
  },
}))

export const StyledBox = styled('div')(({ theme }) => ({
  mx: 2.5,
  marginBottom: "15px",
  marginTop: APP_BAR_MOBILE,
  paddingBottom: 0,
  [theme.breakpoints.up('lg')]: {
    marginTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))

export const TitleStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  display: 'flex',
  alignItems: "center",
  height: '60px',
  justifyContent: "flex-start",
  mb: 0
}))
export const Item_block = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#000',
}));

export const TitleStackButton = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  display: 'flex',
  alignItems: "center",
  height: '60px',
  justifyContent: "space-between",
  mb: 0
}))

export const ButtonStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  display: 'flex',
  alignItems: "center",
  height: '70px',
  justifyContent: "flex-start",
  m: 0
}))

export const StyledContent = styled('div')(({ theme, width }) => ({
  maxWidth: width ? width : 480,
  margin: 'auto',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

export const StyledRootAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.layout.main,
  zIndex: 2000,
  // boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },
}))

export const StyledChip = styled(Chip)(({theme}) => ({
  color: theme.palette.layout.main,
  backgroundColor: theme.palette.layout.element,
  // border: 'solid 1px theme.palette.primary',
}));

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  // textTransform: 'capitalize',
  color: theme.palette.common.white,
  // borderRadius: theme.shape.borderRadius,
  paddingLeft: '15px'
}));


export const StyledRootUpMD = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))


export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 4, 0, 0),
  },
  [theme.breakpoints.down('lg')]: {
    minHeight: HEADER_MOBILE,
    padding: theme.spacing(0, 2, 0, 0),
  },
}))


export const SearchInput = styled(Autocomplete)(({theme}) => ({
  [theme.breakpoints.up('lg')]: {
    "&.Mui-focused .MuiInputLabel-outlined": {
      color: theme.palette.layout.element,
    },
    "& fieldset": {
      color: `${theme.palette.layout.element} !important`,
      borderColor: `${theme.palette.layout.element} !important`,
    },
    "& .MuiAutocomplete-inputRoot": {
      color: theme.palette.layout.element,
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
      color: `${theme.palette.layout.element} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.layout.element
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.layout.element} !important`
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.layout.element
    }
  },
  [theme.breakpoints.down('lg')]: {
    "&.Mui-focused .MuiInputLabel-outlined": {
      color: theme.palette.common.black
    },
    "& .MuiAutocomplete-inputRoot": {
      color: theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.text.secondary
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.text.secondary
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.text.secondary
    }
  },


}))

export const StyledLink = styled(Link)(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.secondary,
  textDecoration: 'none',
}))

export const BorderLinearProgress = styled(LinearProgress)(({ theme, color }) => ({
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.layout.default,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: color,
  },
}));

export const DropzoneBox = styled(Box)(({ theme }) => ({
  borderRadius: 2,
  boxShadow: 1,
  border: `2px ${theme.palette.layout.accent_element} dashed`
}));