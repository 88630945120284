import CircularProgress from '@mui/material/CircularProgress';
import {styled} from '@mui/material/styles';

const Wrapper = styled('div')(({theme}) => ({
  position: 'absolute',
  left: '0',
  top: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.5)',
  zIndex: 1000,
}));

const Loader = styled('div')(({theme}) => ({
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  left: '50%',
  top: '50%',
}));

const LoadingIndicator = () => (
  <Wrapper>
    <Loader>
      <CircularProgress size={60} color='error'/>
    </Loader>
  </Wrapper>
);

export default LoadingIndicator;
