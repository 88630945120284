import {Box, Button, Grid, InputAdornment, Typography} from "@mui/material";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import AlertMessage from "../../components/Alert/AlertMessage";
import Footer from "../../components/Footer";
import CustomAutocomplete from "../../components/Fields/CustomAutocomplete";
import CustomTextField from "../../components/Fields/CustomTextField";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";
import {$authHost, $customHost} from "../../http";
import {webhooksUrl} from "../../http/baseRoute";
import LoadingIndicator from "../../components/LoadingIndicator";

const AzureCreateSubscription = () => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tariffs, setTariffs] = useState([]);

  const {setProducts} = useActions();
  const {products} = useSelector(
    (state) => state.productsReducer,
  );

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    control
  } = methods;

  const onSubmit = async ({product, tariff, DomainPrefix}) => {
    setLoading(true);
    const data = {
      TariffID: tariff?.TariffID,
      DomainPrefix: `${DomainPrefix}.ai.aspex.cloud`
    };
    try {
      // await $customHost.post(`${webhooksUrl}/subscriptions/create`, data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(
        `/product/get_all`,
      );
      setProducts(response.data)
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const watchProduct = useWatch({control, name: 'product'});

  useEffect(() => {
    if (Array.isArray(watchProduct?.Tariffs)) {
      setTariffs(watchProduct?.Tariffs)
    } else {
      setTariffs([])
    }
  }, [watchProduct]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        p: 4
      }}>
        <Grid container spacing={4} justifyContent='center' sx={{maxWidth: '600px'}}>
          <Grid item>
            <Typography variant='h4'>Мастер создание подписки</Typography>
          </Grid>
          <Grid item>
            <Typography>
              Прошу выбрать продукт, для которого Вы хотите создать новую подписку:
            </Typography>
          </Grid>
          <Grid item sx={{width: '100%'}}>
            {loading && <LoadingIndicator/>}
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomAutocomplete
                  name='product'
                  label='Выбор продукта'
                  options={products}
                  getOptionLabel={(option) => option.ProductName || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.ProductID === value.ProductID
                  }
                  required
                />
                <CustomAutocomplete
                  name='tariff'
                  label='Выбор тарифа'
                  options={tariffs}
                  getOptionLabel={(option) => option.TariffName || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.TariffID === value.TariffID
                  }
                  required
                />
                <CustomTextField
                  name='DomainPrefix'
                  label={t('src.pages.AzureRegistration.instanceName')}
                  required
                  registerOptions={{
                    pattern: {
                      value: /^[a-z0-9]{5,12}$/,
                      message: t('main.another.wrongInput'),
                    },
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">.ai.aspex.cloud</InputAdornment>
                  }}
                />
                <AlertMessage/>
                <Button variant='contained' type='submit' disabled
                        sx={{width: '100%'}}>Создать</Button>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
      <Footer/>
    </Box>
  )
}

export default AzureCreateSubscription;