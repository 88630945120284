import React, {useEffect, useState} from 'react'
import {Box, Button, Card, IconButton, InputAdornment} from '@mui/material'
import {FormProvider, useForm, useWatch} from 'react-hook-form'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import {useTranslation} from 'react-i18next'
import {$authHost} from '../../http'
import LoadingSpinner from '../modals/loadingSpinner'
import AlertMessage from '../Alert/AlertMessage'
import {useActions} from '../../hook/useActions'
import Stack from '@mui/material/Stack'
import CustomTextField from "../Fields/CustomTextField";

const AccountChangePassword = () => {

  const {t} = useTranslation()

  const {
    resetAllAlertMessage,
    setSuccessAlertMessage,
    setErrorAlertMessage,
  } = useActions()

  const [showOldPassword, setShowOldPassword] = React.useState(() => {
    return false
  })
  const [showNewPassword, setShowNewPassword] = React.useState(() => {
    return false
  })
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(() => {
    return false
  })
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show)
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show)

  const [loading, setLoading] = useState(false)
  const registerOptions = {
    pattern: {
      value: /^[!-~]{1,32}$/i,
      message: t('src.pages.AccountPage.ChangePassword.uncorrectedError'),
    },
    required: t('main.another.requiredField'),
  }

  const methods = useForm({
    mode: 'onBlur',
  })
  const {
    handleSubmit,
    control,
    reset,
    formState: {isSubmitSuccessful, isDirty},
  } = methods

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await $authHost.patch(`user/change_password`, {
        'password': data.newPassword,
        'old_password': data.oldPassword,
      }).then(response => {
        if (response.status === 201) {
          // reset({})
          resetAllAlertMessage()
          setSuccessAlertMessage(t('src.pages.AccountPage.ChangePassword.passwordСhanged'))
        }
      })
    } catch (e) {
      if (e.response.status === 401 && e.response.data.detail === 'The old and new passwords must not be the same') {
        setErrorAlertMessage(
          t('src.pages.ChangePassword.differentPassword', 'Новый пароль должен отличаться от старого!'))
        // setError('newPassword', {
        // 	type: 'manual',
        // 	// message: t('src.pages.ChangePassword.differentPassword', 'Новый пароль должен отличаться от старого!'),
        // })
      }
      if (e.response.status === 401 && e.response.data.detail === 'The old password is incorrect') {
        setErrorAlertMessage(t('src.pages.AccountPage.ChangePassword.oldPasswordError'))
        // setError('oldPassword', {
        // 	type: 'manual',
        // 	// message: t('src.pages.AccountPage.ChangePassword.oldPasswordError'),
        // })
      }
    } finally {
      setLoading(false)
    }
  }

  const newPassword = useWatch({control, name: 'newPassword', defaultValue: ''})

  useEffect(() => {
    reset({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
  }, [isSubmitSuccessful])
  return (
    <>
      {loading && <LoadingSpinner/>}
      <Box sx={{flexDirection: 'column', px: 0, py: 4}}>

        <AlertMessage/>
        <Card>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  padding: '32px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <CustomTextField
                  name='oldPassword'
                  label={t('src.pages.AccountPage.ChangePassword.oldPassword')}
                  required
                  type={showOldPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowOldPassword}
                          edge="end"
                        >
                          {showOldPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
                <CustomTextField
                  name='newPassword'
                  label={t('src.pages.AccountPage.ChangePassword.newPassword')}
                  secondErrorText={t('src.pages.AccountPage.ChangePassword.uncorrectedError')}
                  required
                  type={showNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
                <CustomTextField
                  name='confirmPassword'
                  label={t('src.pages.AccountPage.ChangePassword.confirmPassword')}
                  required
                  rules={{
                    validate: (value) => {
                      return value === newPassword || t('src.pages.AccountPage.ChangePassword.validPassword')
                    },
                  }}
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{width: '100%'}}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isDirty}
                  >
                    {t('main.another.submitButton', 'Сохранить изменения')}
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => reset()}
                    disabled={!isDirty}
                  >
                    {t('main.another.resetButton', 'Сбросить')}
                  </Button>
                </Stack>
              </div>
            </form>
          </FormProvider>
        </Card>
      </Box>
    </>
  )
}

export default AccountChangePassword