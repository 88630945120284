import {useTranslation} from 'react-i18next'
import {Divider} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {Controller, useFormContext} from "react-hook-form";
import CustomTextField from "../../Fields/CustomTextField";
import React from "react";

const AccessSsoSettingsFields = () => {
  const {t} = useTranslation();

  const {
    control,
    setValue,
  } = useFormContext()

  return (
    <>
      {t('src.pages.AccessSsoPage.accessSSO')}
      <Controller
        name="is_on"
        control={control}
        render={({field}) => {
          return (
            <ToggleButtonGroup
              {...field}
              onChange={(
                event,
                value
              ) => {
                setValue(field.name, value);
              }}
              exclusive
              color="primary"
              size="small"
              sx={{pt: 1, mb: 3, ml: 2}}
            >
              <ToggleButton
                value={true}
                sx={{px: 3, py: 1}}
              >
                вкл
              </ToggleButton>
              <ToggleButton
                value={false}
                sx={{px: 3, py: 1}}
              >
                выкл
              </ToggleButton>
            </ToggleButtonGroup>
          );
        }}
      />

      <Divider sx={{mb: 3}}/>

      <CustomTextField
        name='client_id'
        label={t('src.pages.AccessSsoPage.clientID')}
        required
      />
      <CustomTextField
        name='adfs_urls'
        label={t('src.pages.AccessSsoPage.providerUrl')}
        required
      />
      <CustomTextField
        name='signout_url'
        label='SSO Sign Out URL'
        required
      />
      <CustomTextField
        name='callback_url'
        label={t('src.pages.AccessSsoPage.CallbackUrl')}
        disabled
      />
    </>
  )
}

export default AccessSsoSettingsFields;