import {Box, Button, Grid, IconButton, InputAdornment, Typography} from "@mui/material";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import React, {useEffect, useState} from "react";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import {$customHost} from "../../http";
import {useNavigate} from "react-router-dom";
import {useActions} from "../../hook/useActions";
import AlertMessage from "../../components/Alert/AlertMessage";
import {EventSourcePolyfill} from "event-source-polyfill";
import LinearProgress from "@mui/material/LinearProgress";
import Footer from "../../components/Footer";
import CustomTextField from "../../components/Fields/CustomTextField";
import {authUrl, baseUrl, webhooksUrl} from "../../http/baseRoute";

const AzureChangePassword = () => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [showProgress, setShowProgress] = useState(false);
  const [deployProgress, setDeployProgress] = useState(0);
  const [deployText, setDeployText] = useState('');
  const [subscriptionError, setSubscriptionError] = useState(false);
  const navigate = useNavigate();
  const {setErrorAlertMessage, resetErrorAlertMessage} = useActions();

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    control,
    setError,
  } = methods;

  const watchPassword = useWatch({control, name: 'password'});

  const urlParams = new URLSearchParams(window.location.search);
  const authToken = urlParams.get('auth_token');
  const azureToken = urlParams.get('azure_token');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const runSSE = () => {
    const evtSource = new EventSourcePolyfill(`${baseUrl}/message-stream`, {
      headers: {
        'authorization': `Bearer ${azureToken}`
      },
    })

    evtSource.addEventListener('updateDeployState', (e) => {
        console.log(e);
        if (e.data) {
          const data = JSON.parse(e.data);
          console.log('Parsed data: ', data);
          const {status, text, progress} = data;

          if (status === 'deploying' || status === 'ssl_pending') {
            setDeployProgress(progress);
            setDeployText(text);
          }

          if (status === 'Success') {
            setDeployProgress(progress);
            setDeployText(text);
            evtSource.close();
          }

          if (status === 'failed') {
            setErrorAlertMessage(text);
            setSubscriptionError(true);
            setShowProgress(false);
          }
        }
      }
    )

    evtSource.addEventListener('open', (e) => {
      console.log(e);
      setShowProgress(true);
      setLoading(false);
    })
    evtSource.addEventListener('error', (e) => {
      console.log(e);
      setErrorAlertMessage('Error');
      setSubscriptionError(true);
      setShowProgress(false);
      setLoading(false);
    })
  }

  const createSubscription = async (DomainPrefix) => {
    try {
      runSSE();
      await $customHost.post(`${webhooksUrl}/subscriptions/create`, {
        token: azureToken,
        language: i18n.language
      });
      DomainPrefix && navigate(`/azure-complete?path=${DomainPrefix}`);
    } catch (e) {
      console.log(e);

      if (e.response?.status === 400) {
        setErrorAlertMessage(e.response?.data?.detail);
        setSubscriptionError(true);
        setLoading(false);
        setShowProgress(false);
      }
    }
  }

  const onSubmit = async ({confirmPassword, ...data}) => {
    setLoading(true);
    try {
      const response = await $customHost.post(`${authUrl}/auth/change_password`, {
        ...data,
        token: authToken,
        language: i18n.language
      });

      const {DomainPrefix} = response.data;

      createSubscription(DomainPrefix);
    } catch (e) {
      console.log(e);

      if (e.response?.status === 400) {
        setErrorAlertMessage(e.response?.data?.detail);
      }

      setError("root.serverError", {
        type: "server",
        message: e.message,
      })
      setLoading(false);
    }
  }

  useEffect(() => {
    if (authToken && azureToken) {
      setLoading(false);
    }
  }, [authToken, azureToken])

  const onUnload = () => {
    resetErrorAlertMessage();
  }

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
    return () => {
      onUnload();
      window.removeEventListener("beforeunload", onUnload);
    };
  }, []);

  if (loading) {
    return (
      <LoadingSpinner/>
    )
  }

  if (showProgress) {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%'
        }}>
          <Box sx={{display: 'flex', alignItems: 'center', width: '700px'}}>
            <Box sx={{width: '100%', mr: 1}}>
              <LinearProgress variant="determinate" value={deployProgress}/>
            </Box>
            <Box sx={{minWidth: 35}}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(deployProgress)}%`}</Typography>
            </Box>
          </Box>
          <Typography pt={2}>{deployText}</Typography>
        </Box>
        <Footer/>
      </Box>
    )
  }

  if (subscriptionError) {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
      }}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4, height: '100%'}}>
          <AlertMessage/>
        </Box>
        <Footer/>
      </Box>
    )
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}>
        <Grid container spacing={4} justifyContent='center' sx={{maxWidth: '600px'}}>
          <Grid item>
            <Typography variant='h4'>{t('src.pages.AzureRegistration.emailConfirmed')}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {t('src.pages.AzureRegistration.thanksForConfirmed')}
            </Typography>
          </Grid>
          <Grid item sx={{width: '100%'}}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomTextField
                  name='password'
                  label={t('src.pages.AzureRegistration.password')}
                  required
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
                <CustomTextField
                  name='confirmPassword'
                  label={t('src.pages.AzureRegistration.passwordAgain')}
                  required
                  rules={{
                    validate: (value) => {
                      return value === watchPassword || t('main.another.wrongInput')
                    },
                  }}
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
                <AlertMessage/>
                <Button variant='contained' type='submit'
                        sx={{width: '100%'}}>{t('src.pages.AzureRegistration.send')}</Button>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
      <Footer/>
    </Box>
  )
}

export default AzureChangePassword;