import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import RemoteDynamicTabs from '../../components/Tabs/RemoteDynamicTabs'
import { TitleStack } from '../../theme/standarts_styles'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const Balance = () => {
	const { t } = useTranslation()
	const location = useLocation()

	const tabs = [
		{ label: t('src.pages.Balance.tab1', 'Состояние'), path: 'state' },
		{ label: t('src.pages.Balance.tab2', 'Счета'), path: 'accounts' },
	]

	// Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
	if (!tabs.some(tab => location.pathname.endsWith(tab.path))) {
		let redirectPath = location.pathname;
		if (!location.pathname.endsWith('/')) {
			redirectPath += '/';
		}
		redirectPath += tabs[0].path;
		return <Navigate to={redirectPath} />;
	}

	return (
		<Container>
			<TitleStack>
				<Typography variant="h4">
					{t('src.pages.Balance.head', 'Управление Балансами')}
				</Typography>
			</TitleStack>
			<RemoteDynamicTabs tabs={tabs}/>
			<Outlet/> {/* Отображение содержимого дочерних маршрутов */}
		</Container>
	)
}

export default Balance