import React from 'react'
// @mui
import { styled } from '@mui/material/styles'
import { Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CompletionRegistrationForm from '../forms/CompletionRegistrationForm'
import {StyledContent, StyledRoot, StyledRootUpMD} from "../../theme/standarts_styles";


const CompletionRegistration = () => {
  const { t } = useTranslation()

  return (
    <>
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom align="center">
              {t('src.pages.CompletionRegistration.completionRegistrationPageText')}
            </Typography>
            <CompletionRegistrationForm/>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  )
}

export default CompletionRegistration
