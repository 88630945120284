import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  IconButton,
} from '@mui/material'
import AccountPopover from './AccountPopover'
import Logo from '../../components/Logo'
import SearchFieldInAppBar from './SearchFieldInAppBar'
import useResponsive from '../../hook/useResponsive'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {useRef, useState} from 'react'
import {StyledRootAppBar, StyledToolbar} from '../../theme/standarts_styles'
import SearchIcon from "@mui/icons-material/Search";
import Collapse from "@mui/material/Collapse";
import LanguagePopover from "./LanguagePopover";



Header.propTypes = {
  onOpenNav: PropTypes.func,
}

export default function Header () {
  const { t } = useTranslation()

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const containerRef = useRef(null);
  // const { sseChannelError } = useSelector(state => state.serviceReducer)
  const { status } = useSelector(state => state.authReducer)

  const currentLocation = status === 'Active' || status === 'Locked'
  const isDesktop = useResponsive('up', 'lg')

  return (
    <StyledRootAppBar>
      <StyledToolbar>
        <Logo/>
        <Box sx={{ flexGrow: 1 }}/>
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            width="100%"
            // xs={6}
        >
          <Grid item ref={containerRef}>
            {status === 'Active' && isDesktop &&
              <Collapse
                  in={checked}
                  orientation="horizontal"
              >
                <SearchFieldInAppBar width="300px"/>
              </Collapse>
            }
          </Grid>
          <Grid item>
            {status === 'Active' && isDesktop &&
              <IconButton
                  sx={{
                    color: 'layout.element'
                  }}
                  onClick={handleChange}
              >
                <SearchIcon/>
              </IconButton>
            }
          </Grid>
          <Grid item>
            <LanguagePopover/>
          </Grid>
          <Grid item>
            {currentLocation && <AccountPopover/>}
          </Grid>
        </Grid>
      </StyledToolbar>
    </StyledRootAppBar>
  )
}
