import axios from 'axios'
import { baseUrl } from './baseRoute'
import { store } from '../store/store'
import { rootActions } from '../store/rootActions'

const $host = axios.create({
  baseURL: baseUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
})

const $authHost = axios.create({
  baseURL: baseUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
})

const $customHost = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
})

// Добавляем глобальный перехватчик ошибок
$authHost.interceptors.response.use(
  (response) => {
    // Обработка успешного ответа
    return response
  },
  (error) => {
    // Обработка ошибок
    if (error.response && error.response.status === 403 &&             // 403 - токена нет или устарел
      error.response.data.detail !== 'Неправильный старый пароль') {  // ошибка возникла не при смене пароля
      store.dispatch(rootActions.logOut())  // выкидываем с неактуальным токеном
    }
    // Можно выбросить ошибку или вернуть ее для обработки в другом месте
    // throw error;
    return Promise.reject(error)
  },
)

const authInterceptor = store => config => {
  let data = store.getState().authReducer.access_token
  config.headers.authorization = `Bearer ${data}`
  return config
}
$authHost.interceptors.request.use(authInterceptor(store))

export {
  $host,
  $authHost,
  $customHost,
}