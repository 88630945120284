import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  access_token: null,
  refresh_token: null,
  startTime: null,
  tokenLifetime: null,
  isWarningModalOpen: false,

  roles: null,
  groups: null,
  status: null,
  locked_flags: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut() {
      return initialState
    },
    updateUser(state, action) {
      const {userId, last_name, email, name, roles, groups, status, locked_flags, avatar} = action.payload
      return {...state, userId, last_name, email, name, roles, groups, status, locked_flags, avatar}
    },
    setWarningModalOpen(state, {payload}) {
      state.isWarningModalOpen = payload
    },
    setToken(state, {payload}) {
      return {...state, ...payload}
    },
  },
})

export const {actions: authActions, reducer: authReducer} = authSlice
export default authSlice.reducer
