import {Controller, FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Autocomplete, Button, Grid, TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import CustomTextField from "../../components/Fields/CustomTextField";
import React from "react";

const TenantManagementAdd = ({handleClose}) => {
  const {t} = useTranslation();

  const methods = useForm({
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: {isDirty, isSubmitSuccessful},
  } = methods;

  const onSubmit = async (data) => {
    // setLoading(true);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomTextField
            name='number'
            label='Номер'
            required
          />
          <CustomTextField
            name='name'
            label='Наименование'
            required
          />
          <Controller
            control={control}
            rules={
              {
                // required: 'Обязательное поле',
              }
            }
            name='role'
            render={({
                       field: {ref, ...field},
                       fieldState: {error},
                     }) => {
              return (
                <Autocomplete
                  {...field}
                  freeSolo
                  size='small'
                  sx={{
                    mb: 3,
                  }}
                  options={[1, 2, 3]}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      label='Роль'
                      {...params}
                      inputRef={ref}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  onChange={(e, value) => field.onChange(value)}
                  onInputChange={(_, data) => {
                    if (data) field.onChange(data);
                  }}
                />
              );
            }}
          />
          <Grid item sx={{width: '100%', mb: 2}}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
              <Grid item>
                <Button variant="contained" type="submit">
                  {t('src.components.Intelligence.add')}
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  sx={{ml: 1}}
                  onClick={handleClose}
                >
                  {t('src.components.Intelligence.close')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  color="primary"
                  sx={{ml: 1}}
                  onClick={() => reset()}
                >
                  {t('src.components.Intelligence.clear')}
                </Button>
              </Grid>
            </Stack>
          </Grid>
        </form>
      </FormProvider>
      {/*<BlockFormModal when={isDirty && !isSubmitSuccessful} />*/}
    </>
  )
}

export default TenantManagementAdd;