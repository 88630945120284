import {Box, Button, Card, Grid, Typography} from "@mui/material";
import CustomViewField from "../../components/CustomViewField";
import React, {useEffect, useState} from "react";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";
import {$authHost} from "../../http";
import LoadingIndicator from "../../components/LoadingIndicator";
import {FormProvider, useForm} from "react-hook-form";
import Stack from "@mui/material/Stack";
import CustomTextField from "../../components/Fields/CustomTextField";
import {TitleStack} from "../../theme/standarts_styles";
import Container from "@mui/material/Container";

const MyData = () => {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const {setCompanyData} = useActions();

  const companyData = useSelector(
    (state) => state.companyReducer,
  );

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: companyData,
  });

  const {
    handleSubmit,
    reset,
    setError,
    formState: {isDirty, isSubmitSuccessful},
  } = methods;

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(
        `/user/me`,
      );
      setCompanyData(response.data)
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await $authHost.patch(`/user/me/update`, data);
    } catch (e) {
      setError('root.serverError', {
        type: 'server',
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      setEditMode(false);
      loadData();
    }
  }, [isSubmitSuccessful]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleBack = () => {
    setEditMode(false);
  };

  const handleReset = () => {
    reset();
  };

  if (editMode) {
    return (
      <Container>
        <TitleStack>
          <Typography variant="h4">
            Мои данные
          </Typography>
        </TitleStack>
        <Box pt={2}>
          <Card sx={{mb: 2, p: 3}}>
            {loading && <LoadingIndicator/>}
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} justifyContent='space-between'>
                  <Grid item xs={12} md={4}>
                    <Typography sx={{fontWeight: 'bold', pb: 2}}>Сведения о компании</Typography>
                    <CustomTextField
                      name='CompanyName'
                      label='Наименование компании'
                      required
                    />
                    <CustomTextField
                      name='CompanyIIN'
                      label='ИИН/ИНН'
                      required
                    />
                    <CustomTextField
                      name='CompanyCountry'
                      label='Страна регистрации'
                      required
                    />
                    <CustomTextField
                      name='CompanyWebsite'
                      label='Сайт компании'
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography sx={{fontWeight: 'bold', pb: 2}}>Контактное лицо</Typography>
                    <CustomTextField
                      name='UserLastName'
                      label='Фамилия'
                      required
                    />
                    <CustomTextField
                      name='UserName'
                      label='Имя'
                      required
                    />
                    <CustomTextField
                      name='otchestvo'
                      label='Отчество'
                    />
                    <CustomTextField
                      name='role'
                      label='Роль'
                    />
                    <CustomTextField
                      name='phone'
                      label='Телефон'
                    />
                    <CustomTextField
                      name='Email'
                      label='Email'
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography sx={{fontWeight: 'bold', pb: 2}}>Юридический и фактический адрес</Typography>
                    <CustomTextField
                      name='index'
                      label='Индекс'
                    />
                    <CustomTextField
                      name='country'
                      label='Страна'
                    />
                    <CustomTextField
                      name='oblast'
                      label='Область'
                    />
                    <CustomTextField
                      name='city'
                      label='Город'
                    />
                    <CustomTextField
                      name='street'
                      label='Улица'
                    />
                    <CustomTextField
                      name='house'
                      label='Дом'
                    />
                    <CustomTextField
                      name='flat'
                      label='Офис/Квартира'
                    />
                  </Grid>
                </Grid>
                <Stack direction='row' justifyContent='space-between'>
                  <Stack direction='row' spacing={2}>
                    <Button variant='contained' type='submit'>
                      Сохранить
                    </Button>
                    <Button
                      variant='text'
                      onClick={handleBack}
                    >
                      Назад
                    </Button>
                  </Stack>
                  <Button
                    variant='text'
                    onClick={handleReset}
                  >
                    Сбросить
                  </Button>
                </Stack>
              </form>
            </FormProvider>
          </Card>
        </Box>
      </Container>
    )
  }

  return (
    <Container>
      <TitleStack>
        <Typography variant="h4">
          Мои данные
        </Typography>
      </TitleStack>
      <Box pt={2}>
        <Card sx={{mb: 2, p: 3}}>
          {loading && <LoadingIndicator/>}
          <Grid container spacing={2} justifyContent='space-between'>
            <Grid item xs={12} md={4}>
              <Typography sx={{fontWeight: 'bold', pb: 1}}>Сведения о компании</Typography>
              <CustomViewField label='Наименование компании' text={companyData.CompanyName}/>
              <CustomViewField label='ИИН/ИНН' text={companyData.CompanyIIN}/>
              <CustomViewField label='Страна регистрации' text={companyData.CompanyCountry}/>
              <CustomViewField label='Сайт компании' text={companyData.CompanyWebsite}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{fontWeight: 'bold', pb: 1}}>Контактное лицо</Typography>
              <CustomViewField label='Фамилия' text={companyData.UserLastName}/>
              <CustomViewField label='Имя' text={companyData.UserName}/>
              <CustomViewField label='Отчество' text={''}/>
              <CustomViewField label='Роль' text={''}/>
              <CustomViewField label='Телефон' text={''}/>
              <CustomViewField label='Email' text={companyData.Email}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{fontWeight: 'bold', pb: 1}}>Юридический и фактический адрес</Typography>
              <CustomViewField label='Индекс' text={''}/>
              <CustomViewField label='Страна' text={''}/>
              <CustomViewField label='Область' text={''}/>
              <CustomViewField label='Город' text={''}/>
              <CustomViewField label='Улица' text={''}/>
              <CustomViewField label='Дом' text={''}/>
              <CustomViewField label='Офис/Квартира' text={''}/>
            </Grid>
          </Grid>
          <Button variant='contained' onClick={handleEdit}>
            Редактировать
          </Button>
        </Card>
      </Box>
    </Container>
  )
}

export default MyData;