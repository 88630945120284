import React, {useEffect, useState} from 'react';
import {useActions} from '../../hook/useActions';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import MaterialTable from '../../components/MaterialTable';
import {Button, Card, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingIndicator from "../../components/LoadingIndicator";
import {$authHost} from "../../http";
import {TableCellsWrap} from "../../components/TableCellsWrap";
import Iconify from "../../components/iconify";
import {ButtonStack, TitleStack, TitleStackButton} from "../../theme/standarts_styles";
import MyAccountsAdd from "./MyAccountsAdd";
import MyAccountsView from "./MyAccountsView";
import Container from "@mui/material/Container";
import dayjs from "dayjs";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import RemoteDynamicTabs from "../../components/Tabs/RemoteDynamicTabs";
// import BlockFormModal from "./BlockFormModal";

const MyAccounts = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const {accounts} = useSelector(
    (state) => state.accountsReducer,
  );

  const tabs = [
    {label: 'Все счета', path: 'all', count: accounts.length},
    {label: 'Неоплаченные', path: 'unpaid', count: accounts.length},
    {label: 'Оплаченные', path: 'paid', count: accounts.length},
    {label: 'Отмененные', path: 'canceled', count: accounts.length},
  ]

  // Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
  if (!tabs.some(tab => location.pathname.endsWith(tab.path))) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith('/')) {
      redirectPath += '/';
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath}/>;
  }

  const handleAdd = () => {
    navigate(`/my-accounts/new`)
  };

  return (
    <Container>
      <TitleStackButton>
        <Typography variant="h4">
          Мои счета
        </Typography>
        <Button
          onClick={handleAdd}
          aria-haspopup='true'
          variant='contained'
          startIcon={<Iconify icon='eva:plus-fill'/>}
          sx={{mr: 0}}
        >
          Выставить счет
        </Button>
      </TitleStackButton>
      <RemoteDynamicTabs tabs={tabs}/>
      <Outlet/> {/* Отображение содержимого дочерних маршрутов */}
    </Container>
  );
};

export default MyAccounts;
