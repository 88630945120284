import {Avatar, Box, Drawer, Grid, Link, Typography} from '@mui/material'
import useResponsive from '../../hook/useResponsive'
import {useTranslation} from 'react-i18next'
import NavSection from '../../components/nav-section/NavSection'
import SearchFieldInAppBar from '../header/SearchFieldInAppBar'
import {APP_BAR_DESKTOP, APP_BAR_MOBILE, NAV_WIDTH, NAV_WIDTH_CLOSE} from '../../constants'
import {useSelector} from 'react-redux'
import {useActions} from '../../hook/useActions'
import {useEffect} from 'react'
import React from "react";


export default function Nav() {
  const {t} = useTranslation()

  const isDesktop = useResponsive('up', 'lg')

  const {openNav} = useSelector(state => state.serviceReducer)
  const {CompanyName} = useSelector(state => state.authReducer)

  const {
    toggleOpenNav,
    setOpenNav,
  } = useActions()

  useEffect(() => {
    // if (!isDesktop && openNav) {
    //   toggleOpenNav()
    // }
    if (!isDesktop) {
      setOpenNav(false)
    }
  }, [isDesktop])

  const renderContent = (
    <Grid
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start'
      }}
    >
      <Box
        sx={{
          marginTop: `${APP_BAR_DESKTOP + 10}px`,
        }}
      >
        {CompanyName && (
          <Typography sx={{p: 1}} textAlign='center' variant='h5'>{CompanyName}</Typography>
        )}

        {!isDesktop &&
          <Box sx={{px: 1, pt: 1, pb: 0}}>
            <SearchFieldInAppBar width="100%"/>
          </Box>
        }
        <NavSection/>
      </Box>
    </Grid>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: {lg: 0},
        // width: { lg: openNav && (isDesktop ? NAV_WIDTH : 0) }, // Меняем значение width в зависимости от режима
        width: {lg: openNav ? NAV_WIDTH : NAV_WIDTH_CLOSE},
      }}
    >
      <Drawer
        open={openNav}
        onClose={toggleOpenNav}
        variant={(isDesktop) ? 'permanent' : 'temporary'}
        // ModalProps={{
        //   keepMounted: !isDesktop,
        // }}
        PaperProps={{
          sx: {
            width: openNav ? NAV_WIDTH : NAV_WIDTH_CLOSE,
            overflow: 'hidden',
            backgroundColor: 'background.default',
            // borderRightStyle:isDesktop ? 'dashed' : 'none',
            visibility: !isDesktop && !openNav ? 'hidden' : 'visible'
          },
        }}
      >
        {renderContent}
      </Drawer>
      {/*{(isDesktop || openNav) &&*/}
      {/*  <Drawer*/}
      {/*    open={openNav}*/}
      {/*    onClose={toggleOpenNav}*/}
      {/*    variant={(isDesktop) ? 'permanent' : 'temporary'}*/}
      {/*    ModalProps={{*/}
      {/*      keepMounted: !isDesktop,*/}
      {/*    }}*/}
      {/*    PaperProps={{*/}
      {/*      sx: {*/}
      {/*        width: NAV_WIDTH,*/}
      {/*        backgroundColor: 'default.paper',*/}
      {/*        borderRightStyle: !isDesktop ? 'none' : 'dashed',*/}
      {/*        visibility: !openNav ? 'hidden' : 'visible',*/}
      {/*      },*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {renderContent}*/}
      {/*  </Drawer>*/}
      {/*}*/}
    </Box>
  )
}
