import {Box, Button, Grid, InputAdornment, Typography} from "@mui/material";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {$customHost} from "../../http";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import {useNavigate} from "react-router-dom";
import {useActions} from "../../hook/useActions";
import AlertMessage from "../../components/Alert/AlertMessage";
import {EventSourcePolyfill} from "event-source-polyfill";
import LinearProgress from "@mui/material/LinearProgress";
import Footer from "../../components/Footer";
import CustomTextField from "../../components/Fields/CustomTextField";
import {authUrl, baseUrl, webhooksUrl} from "../../http/baseRoute";

const AzureRegistration = () => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [deployProgress, setDeployProgress] = useState(0);
  const [deployText, setDeployText] = useState('');
  const navigate = useNavigate();
  const {setErrorAlertMessage, resetErrorAlertMessage} = useActions();

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: {isSubmitSuccessful},
    setValue,
  } = methods;

  const watchEmail = useWatch({control, name: 'Email'});

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const onSubmit = async ({confirmEmail, DomainPrefix, ...data}) => {
    resetErrorAlertMessage();
    setLoading(true);
    const newData = {DomainPrefix: `${DomainPrefix}.ai.aspex.cloud`, ...data, language: i18n.language};
    try {
      await $customHost.post(`${authUrl}/auth/sign-up`, newData, {
        params: {
          azure_token: token,
          language: i18n.language
        }
      });
    } catch (e) {
      console.log(e);

      if (e.response?.status === 400) {
        setErrorAlertMessage(e.response?.data?.detail);
      }

      setError("root.serverError", {
        type: "server",
        message: e.message,
      })
    } finally {
      setLoading(false);
    }
  }

  const runSSE = () => {
    const evtSource = new EventSourcePolyfill(`${baseUrl}/message-stream`, {
      headers: {
        'authorization': `Bearer ${token}`
      },
    })

    evtSource.addEventListener('updateDeployState', (e) => {
        console.log(e);
        if (e.data) {
          const data = JSON.parse(e.data);
          console.log('Parsed data: ', data);
          const {status, text, progress} = data;

          if (status === 'deploying' || status === 'ssl_pending') {
            setDeployProgress(progress);
            setDeployText(text);
          }

          if (status === 'Success') {
            setDeployProgress(progress);
            setDeployText(text);
            evtSource.close();
          }

          if (status === 'failed') {
            setErrorAlertMessage(text);
            setSubscriptionError(true);
            setShowProgress(false);
          }
        }
      }
    )

    evtSource.addEventListener('open', (e) => {
      console.log(e);
      setShowProgress(true);
      setLoading(false);
    })
    evtSource.addEventListener('error', (e) => {
      console.log(e);
      setErrorAlertMessage('Error');
      setSubscriptionError(true);
      setShowProgress(false);
      setLoading(false);
    })
  }

  const createSubscription = async (user) => {
    try {
      runSSE();
      await $customHost.post(`${webhooksUrl}/subscriptions/create`, {
        token: token,
        language: i18n.language
      });
      user && navigate(`/azure-complete?path=${user?.DomainPrefix}`);
    } catch (e) {
      console.log(e);

      if (e.response?.status === 400) {
        setErrorAlertMessage(e.response?.data?.detail);
        setSubscriptionError(true);
        setLoading(false);
        setShowProgress(false);
      }
    }
  }

  useEffect(() => {
    if (token) {
      const checkUser = async () => {
        try {
          const response = await $customHost.post(`${authUrl}/auth/check_existence`, {}, {
            params: {
              azure_token: token,
              language: i18n.language
            }
          })

          const {email, user_id, is_exists, is_activated, user} = response.data;

          if (is_exists) {
            if (is_activated) {
              user && navigate(`/azure-complete?path=${user?.DomainPrefix}`);
            }

            if (!is_activated && !user?.HashPassword) {
              setErrorAlertMessage(t('src.pages.AzureRegistration.setPassword'));
              setSubscriptionError(true);
              setLoading(false);
            }

            if (!is_activated && user?.HashPassword) {
              createSubscription(user);
            }
          }

          if (!is_exists) {
            setValue('Email', email);
            setValue('UserID', user_id);
            setLoading(false);
          }
        } catch (e) {
          console.log(e);

          if (e.response?.status === 400) {
            setErrorAlertMessage(e.response?.data?.detail);
            setSubscriptionError(true);
            setLoading(false);
          }
        }
      }

      checkUser();
    }
  }, [token])

  const onUnload = () => {
    resetErrorAlertMessage();
  }

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
    return () => {
      onUnload();
      window.removeEventListener("beforeunload", onUnload);
    };
  }, []);

  if (loading) {
    return (
      <LoadingSpinner/>
    )
  }

  if (showProgress) {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%'
        }}>
          <Box sx={{display: 'flex', alignItems: 'center', width: '700px'}}>
            <Box sx={{width: '100%', mr: 1}}>
              <LinearProgress variant="determinate" value={deployProgress}/>
            </Box>
            <Box sx={{minWidth: 35}}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(deployProgress)}%`}</Typography>
            </Box>
          </Box>
          <Typography pt={2}>{deployText}</Typography>
        </Box>
        <Footer/>
      </Box>
    )
  }

  if (subscriptionError) {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
      }}>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4, height: '100%'}}>
          <AlertMessage/>
        </Box>
        <Footer/>
      </Box>
    )
  }

  if (isSubmitSuccessful) {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          p: 4
        }}>
          <Grid container spacing={4} justifyContent='center' sx={{maxWidth: '600px'}}>
            <Grid item>
              <Typography variant='h4'>{t('src.pages.AzureRegistration.thanks')}</Typography>
            </Grid>
            <Grid item>
              <Typography>
                {t('src.pages.AzureRegistration.emailSend')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Footer/>
      </Box>
    )
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        p: 4
      }}>
        <Grid container spacing={4} justifyContent='center' sx={{maxWidth: '600px'}}>
          <Grid item>
            <Typography variant='h4'>{t('src.pages.AzureRegistration.welcome')}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {t('src.pages.AzureRegistration.thanksForPurchase')}
            </Typography>
          </Grid>
          <Grid item sx={{width: '100%'}}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomTextField
                  name='CompanyName'
                  label={t('src.pages.AzureRegistration.companyName')}
                  required
                />
                <CustomTextField
                  name='CompanyCountry'
                  label={t('src.pages.AzureRegistration.companyCountry')}
                  required
                />
                <CustomTextField
                  name='CompanyAddress'
                  label={t('src.pages.AzureRegistration.companyAddress')}
                  required
                />
                <CustomTextField
                  name='CompanyWebsite'
                  label={t('src.pages.AzureRegistration.companySite')}
                  required
                />
                <CustomTextField
                  name='CompanyIIN'
                  label={t('src.pages.AzureRegistration.companyINN')}
                />
                <CustomTextField
                  name='UserLastName'
                  label={t('src.pages.AzureRegistration.surname')}
                />
                <CustomTextField
                  name='UserName'
                  label={t('src.pages.AzureRegistration.name')}
                />
                <CustomTextField
                  name='Email'
                  label={t('src.pages.AzureRegistration.email')}
                  required
                  rules={{
                    required: t('main.another.requiredField'),
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: t('main.another.wrongInput'),
                    },
                  }}
                />
                <CustomTextField
                  name='confirmEmail'
                  label={t('src.pages.AzureRegistration.emailAgain')}
                  required
                  rules={{
                    validate: (value) => {
                      return value === watchEmail || t('main.another.wrongInput')
                    },
                  }}
                />
                <CustomTextField
                  name='DomainPrefix'
                  label={t('src.pages.AzureRegistration.instanceName')}
                  required
                  registerOptions={{
                    pattern: {
                      value: /^[a-z0-9]{5,12}$/,
                      message: t('main.another.wrongInput'),
                    },
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">.ai.aspex.cloud</InputAdornment>
                  }}
                />
                <AlertMessage/>
                <Button variant='contained' type='submit'
                        sx={{width: '100%'}}>{t('src.pages.AzureRegistration.send')}</Button>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
      <Footer/>
    </Box>
  )
}

export default AzureRegistration;