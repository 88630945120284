import {Navigate, Outlet, useLocation, useParams} from 'react-router-dom';
import Container from '@mui/material/Container';
import {Button, Typography} from '@mui/material';
import RemoteDynamicTabs from "../../components/Tabs/RemoteDynamicTabs";
import {TitleStackButton} from "../../theme/standarts_styles";
import Stack from "@mui/material/Stack";

const SubscriptionManage = () => {
  const location = useLocation();

  const {subscriptionId} = useParams();

  const tabs = [
    {label: 'Общие', path: 'general'},
    {label: 'Транзакции', path: 'transactions'},
    {label: 'Графики', path: 'charts'},
  ];

  // Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
  if (!tabs.some((tab) => location.pathname.endsWith(tab.path))) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith('/')) {
      redirectPath += '/';
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath}/>;
  }

  return (
    <Container>
      <TitleStackButton>
        <Typography variant="h4">
          Управление подпиской
        </Typography>
        <Stack direction='row' spacing={2}>
          <Button variant='outlined'
                  href={`${window.location.origin}/azure/change-tariff-fixed/${subscriptionId}`}
                  target='_blank'
                  rel="noreferrer"
          >
            Сменить тариф fixed
          </Button>
          <Button variant='outlined'
                  href={`${window.location.origin}/azure/change-tariff-prorated/${subscriptionId}`}
                  target='_blank'
                  rel="noreferrer"
          >
            Сменить тариф prorated
          </Button>
          <Button variant='text'>Отменить подписку</Button>
        </Stack>
      </TitleStackButton>
      <RemoteDynamicTabs tabs={tabs}/>
      <Outlet/> {/* Отображение содержимого дочерних маршрутов */}
    </Container>
  );
}

export default SubscriptionManage;