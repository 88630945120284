import {Button, Card, Grid, Tooltip, Typography} from "@mui/material";
import CustomTextField from "../../components/Fields/CustomTextField";
import CustomAutocomplete from "../../components/Fields/CustomAutocomplete";
import CustomDatepicker from "../../components/Fields/CustomDatepicker";
import Stack from "@mui/material/Stack";
import {FormProvider, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import MaterialTable from "../../components/MaterialTable";
import {TableCellsWrap} from "../../components/TableCellsWrap";
import IconButton from "@mui/material/IconButton";
import PaymentIcon from "@mui/icons-material/Payment";
import SettingsIcon from "@mui/icons-material/Settings";
import {useActions} from "../../hook/useActions";
import {$authHost} from "../../http";
import LoadingIndicator from "../../components/LoadingIndicator";

const SubscriptionGeneral = () => {
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {subscription} = useSelector(state => state.subscriptionsReducer);
  const {tariffs} = useSelector(state => state.tariffsReducer);
  const {setSubscription, setTariffs} = useActions();

  const {subscriptionId} = useParams();

  const tableColumns = [
    {
      accessorKey: 'TariffName',
      header: 'Наименование услуги',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'count',
      header: 'Кол-во',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({renderedCellValue, row}) => {
        return (
          <Tooltip title='Купить'>
            <IconButton
              size='large'
              color='inherit'
              onClick={() => handleBuy(row.original.id)}
            >
              <PaymentIcon/>
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const handleBuy = (id) => {
    // setSelectedSubscription(id);
  };

  const getSubscription = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/subscription/get_one/${subscriptionId}`);
      setSubscription(response.data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  const getTariffs = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/tariff/get_all`);
      setTariffs(response.data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getSubscription();
    getTariffs();
  }, []);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: subscription,
  });

  const {
    handleSubmit,
    reset,
    setError,
    formState: {isDirty, isSubmitSuccessful},
  } = methods;

  const onSubmit = async (data) => {
    console.log(data);

    setLoading(true);
    try {
      // await $authHost.patch(`/update/${id}`, data);
    } catch (e) {
      setError('root.serverError', {
        type: 'server',
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate('/my-subscriptions');
    }
  }, [isSubmitSuccessful]);

  const handleBack = () => {
    navigate('/my-subscriptions');
  };

  const handleReset = () => {
    reset();
  };

  return (
    <Grid container direction='row' spacing={2} sx={{my: 2}}>
      {loading && <LoadingIndicator/>}
      <Grid item md={6} xs={6}>
        <Card sx={{px: 2, py: 4}}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomTextField
                name='name'
                label='Наименование подписки'
                required
              />
              <CustomTextField
                name='Tenants.TenantDomainPrefix'
                label='URL тенанта'
                disabled
              />
              <CustomAutocomplete
                name='Tariffs'
                label='Тарифный план'
                options={tariffs}
                getOptionLabel={(option) => option.TariffName || ''}
                isOptionEqualToValue={(option, value) =>
                  option.TariffID === value.TariffID
                }
                required
              />
              <CustomTextField
                name='SubscriptionStatus.Status'
                label='Состояние'
                required
              />
              <CustomTextField
                name='Balance'
                label='Баланс'
                required
              />
              <CustomDatepicker name='ActiveFrom' label='Дата начала' required/>
              <CustomDatepicker name='CalculationDate' label='День расчета' required/>
              <CustomDatepicker name='ActiveTo' label='Дата завершения' required/>
              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' spacing={2}>
                  <Button variant='contained' type='submit'>
                    Сохранить
                  </Button>
                  <Button
                    variant='text'
                    onClick={handleBack}
                  >
                    Назад
                  </Button>
                </Stack>
                <Button
                  variant='text'
                  onClick={handleReset}
                >
                  Сбросить
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </Card>
      </Grid>
      <Grid item md={6} xs={6}>
        <Card sx={{px: 2, py: 4, height: '100%'}}>
          <MaterialTable
            data={Array.isArray(subscription?.TariffServices) ? subscription?.TariffServices : []}
            columns={tableColumns}
            positionExpandColumn='last'
            renderDetailPanel={({row, table}) => (
              <Typography px={2} variant='body2'>{row.original.TariffDescription}</Typography>
            )}
          />
        </Card>
      </Grid>
    </Grid>
  )
}

export default SubscriptionGeneral;