import React, {useState} from 'react'
import {Box, Button, InputAdornment, Typography} from '@mui/material'
import {FormProvider, useForm, useWatch} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import AlertMessage from '../../components/Alert/AlertMessage'
import {useActions} from '../../hook/useActions'
import IconButton from '@mui/material/IconButton'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import {$authHost} from '../../http'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import CustomTextField from "../../components/Fields/CustomTextField";

const CompletionRegistrationForm = () => {
  const {t} = useTranslation()

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleClickShowNewPassword = () => setShowNewPassword(prevState => !prevState)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(prevState => !prevState)

  const methods = useForm({
    mode: 'onBlur',
  })
  const {
    setError,
    control,
    handleSubmit,
  } = methods

  const newPassword = useWatch({control, name: 'newPassword', defaultValue: ''})

  const {
    setErrorAlertMessage,
    setToken,
  } = useActions()

  const {
    status,
  } = useSelector(state => state.authReducer)

  const onSubmit = async (data) => {
    const {newPassword, confirmPassword, ...restData} = data
    const processedData = {
      ...restData,
      password: newPassword,
    }
    try {
      const response = await $authHost.patch(`/user/complete_registration`, processedData)
      if (response.status === 201) {
        const utcResponseTime = new Date(response.headers['date'])
        const startTime = utcResponseTime.getTime() //localTimeMilliseconds
        const {access_token} = response.data
        setToken({access_token, startTime})
        // const { last_name, email, avatar, name, roles, status, locked_flags, access_token } = response.data
        // updateUser({ last_name, email, avatar, name, roles, status, locked_flags, access_token })
        // navigate('/budgets-and-advances')
      }
    } catch (e) {
      if (e.response.status === 401 && e.response.data.detail === 'The old and new passwords must not be the same') {
        setError('newPassword', {
          type: 'manual',
          message: t('src.pages.CompletionRegistration.differentPassword'),
        })
      }
    }
  }

  // useEffect(() => {
  //   if (status === 'Active') {
  //     return <Navigate to="/"/>
  //   }
  // }, [])

  return (
    <>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 2}}>
          <Typography variant="body2" sx={{mb: 1}}>
            {t('src.pages.CompletionRegistration.followingInformation')}
          </Typography>
          <CustomTextField
            name='name'
            label={t('src.pages.AccountPage.UserData.name')}
            required
            autoComplete="given-name"
          />
          <CustomTextField
            name='last_name'
            label={t('src.pages.AccountPage.UserData.last_name')}
            required
            autoComplete="family-name"
          />
          <Typography variant="body2" sx={{mb: 1}}>
            {t('src.pages.CompletionRegistration.changePassword')}
          </Typography>
          <CustomTextField
            name='newPassword'
            label={t('src.pages.AccountPage.ChangePassword.newPassword')}
            required
            secondErrorText={t('src.pages.AccountPage.ChangePassword.uncorrectedError')}
            rules={{
              pattern: {
                value: /^[!-~]{1,32}$/i,
                message: t('src.pages.AccountPage.ChangePassword.uncorrectedError'),
              },
            }}
            type={showNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>,
            }}
          />
          <CustomTextField
            name='confirmPassword'
            label={t('src.pages.AccountPage.ChangePassword.confirmPassword')}
            required
            rules={{
              validate: (value) => {
                return value === newPassword || t('src.pages.AccountPage.ChangePassword.validPassword')
              },
            }}
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mb: 2}}
          >
            {t('main.another.save')}
          </Button>
          <AlertMessage/>
        </Box>
      </FormProvider>
    </>
  )
}

export default CompletionRegistrationForm