import {Box, Typography} from "@mui/material";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
         p={2}>
      <Typography variant='body2' pr={1}>© {year} <a href='https://aspex.cloud' target='_blank'
                                                     rel="noreferrer">Aspex</a>.</Typography>
      <Typography variant='body2'><a href='https://aspex.cloud/privacypolicy' target='_blank' rel="noreferrer">Privacy
        Policy</a></Typography>
    </Box>
  )
}

export default Footer;