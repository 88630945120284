import React from 'react'

const BalanceAccountsPage = () => {
	return (
		<div>

		</div>
	)
}

export default BalanceAccountsPage