import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from "react-i18next";

const CustomAutocomplete = ({
                              name,
                              label,
                              options,
                              required,
                              rules,
                              InputLabelProps,
                              inputProps,
                              sx,
                              value: customValue,
                              getOptionLabel = (option) => option,
                              isOptionEqualToValue = (option, value) => option === value,
                              ...props
                            }) => {
  const {t} = useTranslation();

  const {
    control,
  } = useFormContext()

  if (required) {
    rules = {required: t('main.another.requiredField'), ...rules}
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({field: {onChange, value, ...field}, fieldState: {error}}) => {
        return (
          <Autocomplete
            {...field}
            options={options || []}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            value={customValue ? customValue : value}
            onChange={(e, value) => onChange(value)}
            // TODO: Добавить перевод
            noOptionsText='Нет доступных вариантов'
            sx={{
              mb: 4,
              ...sx,
            }}
            {...props}
            renderInput={params => (
              <TextField
                {...params}
                label={`${label}${required || !!rules?.required ? ' *' : ''}`}
                error={!!error}
                // TODO: Добавить перевод
                helperText={error && (error?.message || 'Unknown error!')}
                FormHelperTextProps={{
                  sx: {
                    position: 'absolute',
                    bottom: '-20px',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  ...InputLabelProps,
                }}
                fullWidth
                size='small'
                variant="outlined"
                {...inputProps}
              />
            )}
          />
        )
      }
      }
    />
  )
}
export default CustomAutocomplete;
