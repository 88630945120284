import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  tariffs: [],
  tariff: null,
  selectedTariff: null,
};

const TariffsSlice = createSlice({
  name: "tariffs",
  initialState,
  reducers: {
    logOut() {
      return initialState
    },
    setTariffs(state, {payload}) {
      state.tariffs = payload;
    },
    setTariff(state, {payload}) {
      state.tariff = payload;
    },
    setSelectedTariff(state, {payload}) {
      const index = state.tariffs.findIndex(
        (tariff) => tariff?.TariffID === payload,
      );
      state.selectedTariff = state.tariffs[index];
    },
  },
});

export const {actions: tariffsActions, reducer: tariffsReducer} = TariffsSlice;
export default TariffsSlice.reducer;
