import React from "react";
import "../../index.css";

export default function LoadingScreen() {

    localStorage.setItem("firstview", false); //TODO redux serviceReducer
    return (
        <div className="loadingscreen-container">
            {/* <img src="image192.png" style={{animation: 'spinner 2s linear infinite'}} width="auto" height="100"/> */}
        </div>
    );
}