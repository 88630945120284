import React from "react";
import { createRoot } from 'react-dom/client';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next) // passes i18n down to react-i18next
	.init({
		// the translations
		// (tip move them in a JSON file and import them,
		// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
		// resources: {
		//   en: {
		//     translation: {
		//       "Welcome to React": "Welcome to React and react-i18next"
		//     }
		//   }
		// },
		lng: 'ru', // if you're using a language detector, do not define the lng option
		debug: true,  // TODO отключить дебаг i18next на прод
		fallbackLng: 'ru',
		escapeValue: false,
		format: function (value, format) {
			if (format === 'html') return value
			return value.toString()
		},
		// returnEmptyString: false,

		// interpolation: {
		//   escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		// }
	})

export default i18n
