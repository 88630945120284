import { useState } from 'react'
import { TextField } from '@mui/material'
import { SearchInput, StyledLink } from '../../theme/standarts_styles'
import { getRoutes } from '../../routes'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const SearchFieldInAppBar = ({ width }) => {
	const [inputValue, setInputValue] = useState('')
	const [autocompleteValue, setAutocompleteValue] = useState(null)
	const { roles } = useSelector(state => state.authReducer)
	const navigate = useNavigate()
	const routes = getRoutes()

	const searchList = []

	const forEachArr = (arr) => {
		arr.forEach((item) => {
			if (item.searchText && roles.some(role => item.roles?.some(r => r.name === role.name))) {
				searchList.push(item)
			}
			if (Array.isArray(item.children)) {
				forEachArr(item.children)
			}
		})
	}

	forEachArr(routes)

	return (
		<>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<SearchInput
					value={autocompleteValue}
					options={searchList}
					size="small"
					sx={{
						width: `${width}`,
					}}
					blurOnSelect={true}
					forcePopupIcon={false}
					isOptionEqualToValue={(option, value) => option.searchText === value}
					getOptionLabel={(option) => option.searchText}
					noOptionsText="Нет доступных вариантов"
					onChange={(event, newValue) => {
						if (newValue) {
							navigate(newValue.path)
						}
						setAutocompleteValue(null)
						setInputValue('')
					}}
					onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
					inputValue={inputValue}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							placeholder="Search..."
							InputProps={{
								// '&::placeholder': {
								// 	color: '#8d8d8d',
								// },
								...params.InputProps,
							}}
							type="search"
						/>
					)}
					renderOption={(props, option) => (
						<li
							{...props}
						>
							<StyledLink to={`/${option.path}`}>{option.searchText}</StyledLink>
						</li>
					)}
				/>
			</div>
		</>
	)
}

export default SearchFieldInAppBar
