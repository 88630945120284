import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {TableCellsWrap} from "../../components/TableCellsWrap";
import {Button, Card, Container, Tooltip, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import {ButtonStack, TitleStack} from "../../theme/standarts_styles";
import MaterialTable from "../../components/MaterialTable";
import Iconify from "../../components/iconify";
import {useSelector} from "react-redux";
import {useActions} from "../../hook/useActions";
import {$authHost} from "../../http";
import PaymentIcon from '@mui/icons-material/Payment';
import SettingsIcon from '@mui/icons-material/Settings';

const MySubscriptions = () => {
  const tableId = 'MySubscriptions';

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {subscriptions} = useSelector(state => state.subscriptionsReducer);

  const {setSubscriptions, setSelectedSubscription} = useActions();

  const tableColumns = [
    {
      accessorKey: 'SubscriptionID',
      header: 'Наименование подписки',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'Products',
      header: 'Наименование продукта',
      accessorFn: (row) => row.Products?.ProductName || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'SubscriptionStatus',
      header: 'Состояние',
      accessorFn: (row) => row.SubscriptionStatus?.Status || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'Balance',
      header: 'Баланс',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({renderedCellValue, row}) => {
        return (
          <>
            <Tooltip title='Оплатить'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => handlePay(row.original.id)}
              >
                <PaymentIcon/>
              </IconButton>
            </Tooltip>
            <Tooltip title='Управление'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => handleManage(row)}
              >
                <SettingsIcon/>
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleManage = (row) => {
    const id = row.original?.SubscriptionID;
    setSelectedSubscription(id);
    navigate(`/my-subscriptions/${id}`);
  };

  const handlePay = (id) => {
    // setSelectedSubscription(id);
    // navigate(`/users/${id}/edit`);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get('/subscription/get_all');
      setSubscriptions(response.data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <TitleStack>
        <Typography variant='h4' sx={{mr: 4}}>
          Мои подписки
        </Typography>
      </TitleStack>
      <ButtonStack>
        <Button
          href={`${window.location.origin}/azure/create-subscription`}
          target='_blank'
          rel="noreferrer"
          aria-haspopup='true'
          variant='contained'
          startIcon={<Iconify icon='eva:plus-fill'/>}
          sx={{mr: 3}}
        >
          Добавить подписку
        </Button>
      </ButtonStack>
      <Card sx={{pt: 1}}>
        <MaterialTable
          id={tableId}
          data={subscriptions}
          columns={tableColumns}
          loading={loading}
        />
      </Card>
    </Container>
  )
}

export default MySubscriptions;