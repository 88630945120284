import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TableCellsWrap } from '../../components/TableCellsWrap'
import { Button, Card, Grid, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../components/modals/loadingSpinner'
import Iconify from '../../components/iconify'
import AlertMessage from '../../components/Alert/AlertMessage'
import MaterialTable from '../../components/MaterialTable'
import BaseAddEditForm from '../../components/Forms/BaseAddEditForm'
import AccessGroupsFields from '../../components/Forms/FormFields/AccessGroupsFields'
import AddEditFormButtonGroup from '../../components/Buttons/AddEditFormButtonGroup'
import { ButtonStack } from '../../theme/standarts_styles'
import { $authHost } from '../../http'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'

const baseGroups = ['Администратор', 'Пользователь']
const AccessGroupsPage = () => {

	const { t } = useTranslation()
	const [selectedRow, setSelectedRow] = useState(null)
	const [loading, setLoading] = useState(false)
	const [openForm, setOpenForm] = useState('')
	const [groupData, setGroupData] = useState('')
	const [roles, setRoles] = useState('')
	const [confirmationId, setConfirmationId] = useState(null)
	const [confirmationForEditForm, setConfirmationForEditForm] = useState(null)
	const fieldsRef = useRef(null)
	const fieldsHeight = fieldsRef?.current?.clientHeight || null

	const tableColumns = [
		{
			accessorKey: 'name',
			header: t('src.pages.AccessGroupsPage.table_name', 'Наименование группы'),
			Cell: ({ renderedCellValue, row }) => (
				<TableCellsWrap>{renderedCellValue}</TableCellsWrap>
			),
		},
		{
			accessorKey: 'roles',
			header: t('src.pages.AccessGroupsPage.table_role', 'Роли'),
			Cell: ({ renderedCellValue, row }) => (
				// <TableCellsWrap>{renderedCellValue.join(', ')}</TableCellsWrap>
				<TableCellsWrap>
					{/*{renderedCellValue.map(role => role.description).join(', ')}*/}
					<div dangerouslySetInnerHTML={{
						__html: [...renderedCellValue].map(role => role.description).sort().join(';<br />'),
					}}/>
				</TableCellsWrap>
			),
		},
		{
			accessorKey: 'group_sso',
			header: t('src.pages.AccessGroupsPage.table_ssoGroup', 'Группа SSO'),
			Cell: ({ renderedCellValue, row }) => (
				<TableCellsWrap>{renderedCellValue}</TableCellsWrap>
			),
		},
		{
			accessorKey: 'actions',
			size: 120,
			enableColumnFilter: false,
			enableSorting: false,
			Cell: ({ renderedCellValue, row }) => {
				if (confirmationId === row.original.id) {
					return (
						<>
							<Tooltip title="Подтвердить">
								<IconButton
									size="large"
									color="inherit"
									onClick={() => handleDeleteRow(row.original.id)}
								>
									<CheckIcon/>
								</IconButton>
							</Tooltip>
							<Tooltip title="Отмена">
								<IconButton
									size="large"
									color="inherit"
									onClick={() => setConfirmationId(null)}
								>
									<ClearIcon/>
								</IconButton>
							</Tooltip>
						</>
					)
				}
				return <>
					<IconButton size="large" color="primary" onClick={() => handleGoToEditPage(row.original)}>
						<EditIcon/>
					</IconButton>
					{!baseGroups.includes(row.original.name) &&  // группы из массвива нельзя удалять
						<IconButton size="large" color="primary" onClick={() => setConfirmationId(row.original.id)}>
							<DeleteIcon/>
						</IconButton>
					}
				</>
			},
		},
	]

	function handleGoToEditPage (row) {
		setSelectedRow(row)
		setOpenForm('Update')
	}

	const handleAdd = () => {
		// setSelectGeneralData(null)
		setOpenForm(prevState => {
			return prevState === 'Add' ? '' : 'Add'
		})
		setSelectedRow(null)
	}

	async function handleDeleteRow (id_row) {
		setLoading(true)
		try {
			await $authHost.delete(`/group/${id_row}/delete`)
			loadData()
			if (id_row === selectedRow.id) {
				setOpenForm('')
			}
		} catch (e) {
		} finally {
			setLoading(false)
		}
	}

	const getDropdownData = useCallback(async () => {
		try {
			const response = await $authHost.get(`/group/get_roles`)
			setRoles(response.data)
		} catch (e) {
		} finally {
		}
	}, [])

	const onSubmit = async (data) => {
		setLoading(true)
		if (openForm === 'Update') {
			setLoading(true)
			const { updated_at, created_at, id, ...update_data } = data
			if (!update_data.id && !!update_data._id) {
				update_data.id = update_data._id  //Костыль для монго дб
			}
			try {
				await $authHost.patch(`/group/${id}/update`, update_data)
				loadData()
				setOpenForm('')
			} catch (e) {
			} finally {
				setLoading(false)
			}
		} else if (openForm === 'Add') {
			const { id, updated_at, created_at, users, ...add_data } = data
			try {
				await $authHost.post(`/group/create`, add_data)
				loadData()
				setOpenForm('')
			} catch (e) {
			} finally {
			}
		}
		setLoading(false)
	}

	const loadData = useCallback(() => {
		try {
			const newChannelList = async () => {
				const response = await $authHost.get('/group/get_all')
				return response.data
			}
			newChannelList().then((data) => {
				setGroupData(data)
			})
		} catch (e) {
		}
	}, [])

	useEffect(() => {
		loadData()
	}, [])
	useEffect(() => {
		getDropdownData()
	}, [getDropdownData])

	return (
		<>
			{loading && <LoadingSpinner/>}
			<>
				<ButtonStack>
					<Button
						onClick={handleAdd}
						aria-haspopup="true"
						variant="contained"
						startIcon={<Iconify icon="eva:plus-fill"/>}
						sx={{ mr: 0 }}
					>
						{t('src.pages.AccessGroupsPage.addGroup')}
					</Button>
				</ButtonStack>
				<AlertMessage/>
				{(openForm) &&
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Card sx={{ p: 3, mb: 2 }}>
								<BaseAddEditForm onSubmit={onSubmit}
																 selectedRow={selectedRow}
																 action={openForm}
																 fieldsRef={fieldsRef}
								>
									<AccessGroupsFields autocompleteOptions={roles} selectedRow={selectedRow} baseGroups={baseGroups}/>
									<AddEditFormButtonGroup
										action={openForm}
										handleClose={() => setOpenForm('')}
										handleDeleteRow={() => handleDeleteRow(selectedRow.id)}
										confirmationForEditForm={confirmationForEditForm}
										setConfirmationForEditForm={setConfirmationForEditForm}
										selectedRow={selectedRow}
										baseGroups={baseGroups}
									/>
								</BaseAddEditForm>
							</Card>
						</Grid>
					</Grid>
				}
				<Card sx={{ pt: 1 }}>
					<MaterialTable
						data={groupData}
						columns={tableColumns}
					/>
				</Card>
			</>
		</>
	)
}

export default AccessGroupsPage