import {Outlet} from 'react-router-dom';
import {Box} from "@mui/material";

const LandingLayout = () => {
  return (
    <Box sx={{minHeight: '100%', display: 'flex'}}>
      <Outlet/>
    </Box>
  )
}

export default LandingLayout;