import {Box, Button, Grid, IconButton, InputAdornment, Typography} from "@mui/material";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import React, {useState} from "react";
import {$customHost} from "../../http";
import AlertMessage from "../../components/Alert/AlertMessage";
import Footer from "../../components/Footer";
import CustomTextField from "../../components/Fields/CustomTextField";

const AzureCreatePassword = () => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(true);

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    control,
  } = methods;

  const watchPassword = useWatch({control, name: 'password'});

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const onSubmit = async ({confirmPassword, ...data}) => {
    setLoading(true);
    try {
      // await $customHost.post(`https://auth.aspex.cloud/auth/change_password`, {data});
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}>
        <Grid container spacing={4} justifyContent='center' sx={{maxWidth: '600px'}}>
          <Grid item>
            <Typography variant='h4'>{t('src.pages.AzureRegistration.emailConfirmed')}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {t('src.pages.AzureRegistration.thanksForConfirmed')}
            </Typography>
          </Grid>
          <Grid item sx={{width: '100%'}}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomTextField
                  name='last_name'
                  label='Фамилия'
                />
                <CustomTextField
                  name='first_name'
                  label='Имя'
                />
                <CustomTextField
                  name='password'
                  label={t('src.pages.AzureRegistration.password')}
                  required
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
                <CustomTextField
                  name='confirmPassword'
                  label={t('src.pages.AzureRegistration.passwordAgain')}
                  required
                  rules={{
                    validate: (value) => {
                      return value === watchPassword || t('main.another.wrongInput')
                    },
                  }}
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
                <AlertMessage/>
                <Button variant='contained' type='submit'
                        sx={{width: '100%'}}>{t('src.pages.AzureRegistration.send')}</Button>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
      <Footer/>
    </Box>
  )
}

export default AzureCreatePassword;