import React, {useEffect, useState} from 'react';
import {useActions} from '../../hook/useActions';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import MaterialTable from '../../components/MaterialTable';
import {Box, Card, IconButton, Tooltip} from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingIndicator from "../../components/LoadingIndicator";
import {$authHost} from "../../http";
import {TableCellsWrap} from "../../components/TableCellsWrap";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

const MyAccountsAll = () => {
  const {t} = useTranslation();
  const navigate = useNavigate()

  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);

  const {setMyAccounts, setSelectedMyAccount} = useActions();
  const {accounts} = useSelector(
    (state) => state.accountsReducer,
  );

  const tableColumns = [
    {
      accessorKey: 'Accounts',
      header: 'Наименование счета',
      accessorFn: (row) => row.Accounts?.AccountName || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'InvoiceDate',
      header: 'Дата',
      accessorFn: (row) => dayjs(row.InvoiceDate).format('DD.MM.YYYY') || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'TotalAmount',
      header: 'Сумма',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'state',
      header: 'Состояние',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'PaymentMethods',
      header: 'Способ оплаты',
      accessorFn: (row) => row.PaymentMethods?.PaymentMethodName || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
      muiTableBodyCellProps: {
        onClick: (e) => {
        },
        sx: {
          cursor: 'auto',
        },
      },
      Cell: ({renderedCellValue, row}) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title='Подтвердить'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => handlePay(row.original.id)}
                >
                  <CheckIcon/>
                </IconButton>
              </Tooltip>
              <Tooltip title='Отмена'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(null)}
                >
                  <ClearIcon/>
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <>
            <Tooltip title='Оплатить'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => setConfirmationId(row.original.id)}
              >
                <PaymentIcon/>
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handlePay = async (row) => {
    try {
      // await $authHost.delete(`/contacts/${row}/delete/`).then((response) => {
      //   loadData();
      // });
    } catch (e) {
    }
  }

  const handleView = (row) => {
    console.log(row);
    setSelectedRow(row.original);
    setSelectedMyAccount(row.original.InvoiceID);

    navigate(`/my-accounts/${row.original.InvoiceID}`)

    // openForm !== 'View' && setOpenForm('View');
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(
        `/invoice/get_all`,
      );
      setMyAccounts(response.data)
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Box pt={2}>
      <Card sx={{pt: 1}}>
        {/*{loading && <LoadingIndicator />}*/}
        <MaterialTable
          data={accounts}
          columns={tableColumns}
          loading={loading}
          muiTableBodyCellProps={({row}) => ({
            onClick: () => {
              handleView(row);
            },
            sx: {
              cursor: 'pointer',
            },
          })}
        />
      </Card>
    </Box>
  );
};

export default MyAccountsAll;
