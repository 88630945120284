import React, {useEffect, useState} from 'react';
import {useActions} from '../../hook/useActions';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import MaterialTable from '../../components/MaterialTable';
import {Button, Card, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {$authHost} from "../../http";
import {TableCellsWrap} from "../../components/TableCellsWrap";
import Iconify from "../../components/iconify";
import {ButtonStack, TitleStack} from "../../theme/standarts_styles";
import TenantManagementAdd from "./TenantManagementAdd";
import TenantManagementView from "./TenantManagementView";
import Container from "@mui/material/Container";
// import BlockFormModal from "./BlockFormModal";

const TenantManagement = () => {
  const {t} = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState('');
  const [confirmationId, setConfirmationId] = useState(null);

  const {setTenants, setSelectedTenant} = useActions();

  const {tenants, selectedTenant} = useSelector(
    (state) => state.tenantsReducer,
  );

  const tableColumns = [
    {
      accessorKey: 'TenantID',
      header: 'Номер',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'TenantName',
      header: 'Наименование',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'role',
      header: 'Роль',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
      muiTableBodyCellProps: {
        onClick: (e) => {
        },
        sx: {
          cursor: 'auto',
        },
      },
      Cell: ({renderedCellValue, row}) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title='Подтвердить'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => handleChange(row.original.id)}
                >
                  <CheckIcon/>
                </IconButton>
              </Tooltip>
              <Tooltip title='Отмена'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(null)}
                >
                  <ClearIcon/>
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <>
            <Tooltip title='Сменить тенант'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => setConfirmationId(row.original.id)}
              >
                <ChangeCircleIcon/>
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleChange = async (row) => {
    try {
      // await $authHost.delete(`/contacts/${row}/delete/`).then((response) => {
      //   loadData();
      // });
    } catch (e) {
    }
  }

  const handleAdd = () => {
    setSelectedRow(null);
    setOpenForm((prevState) => {
      return prevState === 'Add' ? '' : 'Add';
    });
  };

  const handleView = (row) => {
    setSelectedRow(row.original);
    setSelectedTenant(row.id);

    openForm !== 'View' && setOpenForm('View');
  };

  const handleClose = () => {
    setOpenForm('');
  }

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(
        `/tenant/me`,
      );

      setTenants(response.data)
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!openForm && selectedTenant) {
      setSelectedTenant(null);
    }
  }, [openForm]);

  return (
    <Container sx={{pb: 0}}>
      <TitleStack>
        <Typography variant='h4' gutterBottom sx={{mb: 0}}>
          Управление тенантами
        </Typography>
      </TitleStack>
      {/*{loading && <LoadingIndicator />}*/}
      <ButtonStack>
        <Button
          href={`${window.location.origin}/azure/create-tenant`}
          target='_blank'
          rel="noreferrer"
          aria-haspopup='true'
          variant='contained'
          startIcon={<Iconify icon='eva:plus-fill'/>}
          sx={{mr: 0}}
        >
          Добавить тенант
        </Button>
      </ButtonStack>
      {/*<AlertMessage/>*/}

      {openForm && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card sx={{p: 3, mb: 2}}>
              {openForm === 'Add' && (
                <TenantManagementAdd handleClose={handleClose}/>
              )
              }
              {openForm === 'View' && (
                <TenantManagementView handleClose={handleClose} account={selectedRow}/>
              )
              }
            </Card>
          </Grid>
        </Grid>
      )}
      <Card sx={{pt: 1}}>
        <MaterialTable
          data={tenants}
          columns={tableColumns}
          loading={loading}
          muiTableBodyCellProps={({row}) => ({
            onClick: () => {
              handleView(row);
            },
            sx: {
              cursor: 'pointer',
            },
          })}
        />
      </Card>
      {/*<BlockFormModal when={!!confirmationId} title='Вы не завершили действие внутри таблицы' />*/}
    </Container>
  );
};

export default TenantManagement;
