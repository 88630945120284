import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  documents: [],
};

const DocumentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    logOut() {
      return initialState
    },
    setDocuments(state, {payload}) {
      state.documents = payload
    },
  },
});

export const {actions: documentsActions, reducer: documentsReducer} = DocumentsSlice
export default DocumentsSlice.reducer;
