import { IconButton, Tooltip, Typography } from "@mui/material";
import SkeletonInput from './SkeletonInput'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DoneIcon from '@mui/icons-material/Done'
import Stack from '@mui/material/Stack'

const CustomViewField = ({
	label,
	text,
	loading,
	sx = {},
	type = 'text',
	path = '',
	copyButton = false,
}) => {
	const [copied, setCopied] = useState(false)

	const copyToClipboard = () => {
		const textToCopy = renderText()
		navigator.clipboard.writeText(textToCopy).then(() => {
			setCopied(true)
		}).catch(error => {
			console.error('Ошибка копирования в буфер обмена: ', error)
		})
	}
	const renderText = () => {
		if (text && (typeof text === 'string' || typeof text === 'number')) {
			return text
		}
		return 'Не найдено'
	}

	if (type === 'link') {
		return (
			<>
				<Typography variant="caption">{label}</Typography>
				<SkeletonInput loading={loading}>
					<Link to={path} target="_blank">
						<Typography
							sx={{
								mb: 2,
								...sx,
							}}
						>
							{renderText()}
						</Typography>
					</Link>
				</SkeletonInput>
			</>
		)
	}

	if (type === 'text') {
		return (
			<>
				<Typography variant="caption">{label}</Typography>
				<Stack direction="row" alignItems="center" sx={{
					pb: 2,
				}}>
					<SkeletonInput loading={loading}>
						<Typography
							sx={{
								pr: 1,
								...sx,
							}}
						>
							{renderText()}
						</Typography>
					</SkeletonInput>
					{(copyButton && !copied) &&
						<Tooltip title="Копировать">
							<IconButton size="small" color="inherit" onClick={() => copyToClipboard()}>
								<FileCopyIcon/>
							</IconButton>
						</Tooltip>
					}
					{(copyButton && copied) &&
						<IconButton size="small" color="inherit" onClick={() => copyToClipboard()}>
							<DoneIcon/>
						</IconButton>}
				</Stack>
			</>
		)
	}

	return <></>
}
export default CustomViewField
