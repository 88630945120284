import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  selectedUser: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logOut() { return initialState },
    setUsers(state, { payload }) { state.users = payload },
    setSelectedUser(state, { payload }) {
      const index = state.users.findIndex(
          (user) => user.id === payload
      );
      state.selectedIselectedUserntellegence = state.users[index];
    },



  },
});

export const { actions: usersActions, reducer: usersReducer } = usersSlice
export default usersSlice.reducer;
