import {Typography} from '@mui/material'
import Container from '@mui/material/Container'
import {useTranslation} from 'react-i18next'
import RemoteDynamicTabs from '../../components/Tabs/RemoteDynamicTabs'
import {TitleStack} from '../../theme/standarts_styles'
import {Navigate, Outlet, useLocation} from 'react-router-dom'

const AccountPage = () => {
  const {t} = useTranslation()
  const location = useLocation()

  const tabs = [
    {label: t('src.pages.AccountPage.tab1'), path: 'user-data'},
    {label: t('src.pages.AccountPage.tab2'), path: 'user-change-password'},
  ]

  // Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
  if (!tabs.some(tab => location.pathname.endsWith(tab.path))) {
		let redirectPath = location.pathname
    if (!location.pathname.endsWith('/')) {
			redirectPath += '/'
    }
		redirectPath += tabs[0].path
		return <Navigate to={redirectPath}/>
  }

  return (
    <Container>
      <TitleStack>
        <Typography variant="h4">
          {t('src.pages.AccountPage.head')}
        </Typography>
      </TitleStack>
      <RemoteDynamicTabs tabs={tabs}/>
      <Outlet/> {/* Отображение содержимого дочерних маршрутов */}
    </Container>
  )
}

export default AccountPage
