import {all} from "redux-saga/effects"
// import {SSEWatcher} from "./getSSESaga";
import { watchTokenChanges } from './authSaga'
// import {sandboxSSEWatcher} from "./getSandboxSSESaga";
export function* rootWatcher() {
    yield all([
        // SSEWatcher(),
        // watchTokenChanges(),
        // sandboxSSEWatcher()
    ])
}
