import React, {useEffect, useState} from "react";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";
import {TableCellsWrap} from "../../components/TableCellsWrap";
import dayjs from "dayjs";
import {Box, Card, IconButton, Tooltip} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import {$authHost} from "../../http";
import MaterialTable from "../../components/MaterialTable";

const PersonalDocuments = () => {
  const [loading, setLoading] = useState(false);

  const {setDocuments} = useActions();

  const {documents} = useSelector(
    (state) => state.documentsReducer,
  );

  const tableColumns = [
    {
      accessorKey: 'number',
      header: '№ акта выполненных работ',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'date',
      header: 'Дата создания',
      accessorFn: (row) => dayjs(row.date).format('DD.MM.YYYY') || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'amount',
      header: 'Сумма',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({renderedCellValue, row}) => {
        return (
          <>
            <Tooltip title='Скачать'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => {
                }}
              >
                <DownloadIcon/>
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(
        `/invoice/get_all`,
      );
      setDocuments(response.data)
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    // loadData();
  }, []);

  return (
    <Box pt={2}>
      <Card sx={{pt: 1}}>
        <MaterialTable
          data={[{number: 1}]}
          columns={tableColumns}
          loading={loading}
        />
      </Card>
    </Box>
  )
}

export default PersonalDocuments;