import TextField from '@mui/material/TextField'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from "react-i18next";

const CustomTextField = ({name, label, required, rules, inputLabelProps, sx, ...props}) => {
  const {t} = useTranslation();

  const {
    control,
  } = useFormContext();

  if (required) {
    rules = {required: t('main.another.requiredField'), ...rules}
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
                 field: {onChange, value, ...field},
                 fieldState: {error},
               }) => {
        return (
          <TextField
            {...field}
            value={value}
            onChange={(e) => {
              onChange(e);
            }}
            label={`${label}${required || !!rules?.required ? ' *' : ''}`}
            error={!!error}
            // TODO: Добавить перевод
            helperText={error && (error?.message || 'Unknown error!')}
            FormHelperTextProps={{
              sx: {
                position: 'absolute',
                bottom: '-20px',
              },
            }}
            InputLabelProps={{
              shrink: true,
              ...inputLabelProps,
            }}
            sx={{
              mb: 4,
              ...sx,
            }}
            fullWidth
            size='small'
            variant="outlined"
            {...props}
          />
        );
      }}
    />
  )
}

export default CustomTextField;
