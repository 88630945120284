import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  products: [],
};

const ProductsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    logOut() {
      return initialState
    },
    setProducts(state, {payload}) {
      state.products = payload
    },
  },
});

export const {actions: productsActions, reducer: productsReducer} = ProductsSlice
export default ProductsSlice.reducer;
