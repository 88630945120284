import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useActions} from "../../hook/useActions";
import {TableCellsWrap} from "../../components/TableCellsWrap";
import {$authHost} from "../../http";
import {Card, Typography} from "@mui/material";
import dayjs from "dayjs";
import MaterialTable from "../../components/MaterialTable";

const SubscriptionTransactions = () => {
  const tableId = 'SubscriptionTransactions';

  const [loading, setLoading] = useState(false);
  const {setTransactions} = useActions();
  const {transactions} = useSelector((state) => state.subscriptionsReducer);

  const {subscriptionId} = useParams();

  const tableColumns = [
    {
      accessorKey: 'Date',
      header: 'Дата',
      accessorFn: (row) => dayjs(row.date).format('DD.MM.YYYY') || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'ResourceName',
      header: 'Наименование транзакции',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'total',
      header: 'Сумма',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   accessorKey: 'details',
    //   header: 'Детали',
    //   Cell: ({renderedCellValue, row}) => (
    //     <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
    //   ),
    // },
  ];

  const loadData = () => {
    setLoading(true);
    try {
      const getData = async () => {
        const response = await $authHost.get(
          `/metriks/all?subscription_id=${subscriptionId}`,
        );
        setTransactions(response.data);
        setLoading(false);
      };
      getData();
    } catch (e) {
    }
  };


  useEffect(() => {
    loadData();
  }, []);

  return (
    <Card sx={{py: 1, my: 2}}>
      <MaterialTable
        id={tableId}
        data={transactions}
        columns={tableColumns}
        loading={loading}
        positionExpandColumn='last'
        renderDetailPanel={({row, table}) => (
          <Typography px={2} variant='body2'>Тут детали</Typography>
        )}
      />
    </Card>
  )
}

export default SubscriptionTransactions;