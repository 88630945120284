import React from 'react'
import {useTranslation} from 'react-i18next'
import CustomMultiTextFieldAutocompleteForObject from '../../Fields/CustomMultiTextFieldAutocompleteForObject'
import CustomTextField from "../../Fields/CustomTextField";

const AccessUserFields = ({autocompleteOptions}) => {

  const {t} = useTranslation()

  return (
    <>
      <CustomTextField
        name='email'
        label={t('src.components.Forms.FormFields.AccessGroupsFields.table_email', 'E-mail')}
        required
      />
      <CustomTextField
        name='name'
        label={t('src.components.Forms.FormFields.AccessGroupsFields.table_name', 'Имя')}
        required
      />
      <CustomTextField
        name='last_name'
        label={t('src.components.Forms.FormFields.AccessGroupsFields.table_lastName', 'Фамилия')}
        required
      />
      <CustomMultiTextFieldAutocompleteForObject
        multiple={true}
        name="groups"
        label={t('src.components.Forms.FormFields.AccessGroupsFields.table_groups', 'Группа')}
        options={autocompleteOptions}
        rules={{
          required: t('main.another.requiredField'),
        }}
        sx={{width: '100%', fontSize: '12px', mb: 3}}
        size="small"
      />
    </>
  )
}

export default AccessUserFields