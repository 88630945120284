import AccessSsoSettingsFields from "../../components/Forms/FormFields/AccessSsoSettingsFields";
import {Button, Card, Grid, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {$authHost} from "../../http";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";

const AccessSsoSettingsPage = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const {
    setSettings,
  } = useActions();

  const {
    settings,
  } = useSelector(state => state.ssoReducer)

  const methods = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    reset(settings);
  }, [settings]);

  const {
    handleSubmit,
    reset,
  } = methods;

  const loadData = async () => {
    setLoading(true)
    try {
      const {data} = await $authHost.get(`/sso/`);
      setSettings(data);
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await $authHost.patch(`/sso/update`, data)
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <Card sx={{p: 3, mb: 2, mt: 2}}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AccessSsoSettingsFields/>
          <Grid item sx={{width: '100%', mb: 2}}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
              <Grid item>
                <Button variant="contained" type="submit" disabled={loading}>
                  {t('main.another.save')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  color="primary"
                  sx={{ml: 1}}
                  onClick={() => reset()}
                  disabled={loading}
                >
                  {t('src.components.Intelligence.reset')}
                </Button>
              </Grid>
            </Stack>
          </Grid>
        </form>
      </FormProvider>
    </Card>
  )
}

export default AccessSsoSettingsPage;