import {Box, Button, Grid, InputAdornment, Typography} from "@mui/material";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Footer from "../../components/Footer";
import CustomAutocomplete from "../../components/Fields/CustomAutocomplete";
import CustomTextField from "../../components/Fields/CustomTextField";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";
import {$authHost} from "../../http";
import Stack from "@mui/material/Stack";
import CustomDatepicker from "../../components/Fields/CustomDatepicker";
import LoadingIndicator from "../../components/LoadingIndicator";
import {useParams} from "react-router-dom";

const AzureChangeTariffProrated = () => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);

  const {subscriptionId} = useParams();

  const {subscription} = useSelector(state => state.subscriptionsReducer);
  const {tariffs} = useSelector(state => state.tariffsReducer);
  const {setSubscription, setTariffs} = useActions();

  const methodsOldTariff = useForm({
    mode: 'onBlur',
    defaultValues: subscription,
  });

  const methodsNewTariff = useForm({
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    control,
    setValue,
  } = methodsNewTariff;

  const onSubmit = async ({DomainPrefix, ...data}) => {
    setLoading(true);
    try {
      // await $customHost.post(`https://auth.aspex.cloud/auth/sign-up`, newData);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  const getSubscription = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/subscription/get_one/${subscriptionId}`);
      setSubscription(response.data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  const getTariffs = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/tariff/get_all?tariff_type=Tariff`);
      setTariffs(response.data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getSubscription();
    getTariffs();
  }, []);

  const watchTariff = useWatch({control, name: 'tariff'});

  useEffect(() => {
    if (watchTariff && watchTariff?.TariffDescription) {
      setValue('tariff_description', watchTariff?.TariffDescription)
    } else {
      setValue('tariff_description', '')
    }
  }, [watchTariff]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        p: 4
      }}>
        {loading && <LoadingIndicator/>}
        <Grid container spacing={4} alignItems='center' direction='column'>
          <Grid item>
            <Typography variant='h4'>Мастер смены тарифа</Typography>
          </Grid>
          <Grid item>
            <Typography>
              Для смены тарифа, выберете новый тариф
            </Typography>
          </Grid>
          <Grid item sx={{width: '100%'}}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Stack spacing={2} alignItems='center'>
                  <Typography>Текущий тариф</Typography>
                  <FormProvider {...methodsOldTariff}>
                    <form style={{width: '100%'}}>
                      <CustomTextField
                        name='Tariffs.TariffName'
                        label='Текущий тариф'
                        disabled
                      />
                      <CustomTextField
                        name='Tariffs.TariffDescription'
                        label='Условия тарифа'
                        disabled
                      />
                      <CustomDatepicker name='CalculationDate' label='Дата следующего платежа' disabled/>
                      <CustomDatepicker name='ActiveTo' label='Дата завершения действия тарифа' disabled/>
                    </form>
                  </FormProvider>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={2} alignItems='center'>
                  <Typography>Новый тариф</Typography>
                  <FormProvider {...methodsNewTariff}>
                    <form id='form-change-tariff-fixed' onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                      <CustomAutocomplete
                        name='tariff'
                        label='Выбор тарифа'
                        options={tariffs}
                        getOptionLabel={(option) => option.TariffName || ''}
                        isOptionEqualToValue={(option, value) =>
                          option.TariffID === value.TariffID
                        }
                        required
                      />
                      <CustomTextField
                        name='tariff_description'
                        label='Условия тарифа'
                        disabled
                      />
                      <CustomDatepicker name='date1' label='Дата начала действия тарифа' disabled/>
                      <CustomDatepicker name='date2' label='Дата завершения действия тарифа' disabled/>
                    </form>
                  </FormProvider>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography>
              Тариф будет изменен в течении часа, ознакомитесь с таблицей расчета, неиспользованные средства будет
              возвращены на счет предоплаты и автоматически использованы при смене тарифа
            </Typography>
          </Grid>
          <Grid item>
            <Stack alignItems='center' spacing={1}>
              <Typography variant='h6'>
                Расчет по тарифу:
              </Typography>
              <Typography>
                Текущий баланс подписки: 1120
              </Typography>
              <Typography>
                Использованные средства по сегодняшний момент: 350
              </Typography>
              <Typography>
                Остаток средств: 770
              </Typography>
              <Typography>
                Стоимость тарифа: 1500
              </Typography>
              <Typography>
                Итоговый баланс: -730
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Typography>
              Внимание, новый баланс отрицателен, подписка будет отключена. Необходимо пополнить баланс на
              сумму: 730. При смене тарифа будет выставлен счет на эту сумму, который необходимо оплатить.
            </Typography>
          </Grid>
          <Grid item>
            <Button variant='contained' type='submit' form='form-change-tariff-fixed'>Сменить тариф</Button>
          </Grid>
        </Grid>
      </Box>
      <Footer/>
    </Box>
  )
}

export default AzureChangeTariffProrated;