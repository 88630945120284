import {Box, Button, Grid, Typography} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import AlertMessage from "../../components/Alert/AlertMessage";
import Footer from "../../components/Footer";
import CustomTextField from "../../components/Fields/CustomTextField";
import {$authHost} from "../../http";
import LoadingIndicator from "../../components/LoadingIndicator";
import {useSelector} from "react-redux";

const AzureCreateTenant = () => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);

    const { UserID } = useSelector(state => state.authReducer)

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    handleSubmit
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await $authHost.post(`/tenant/create?owner_id=${UserID}`, data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        p: 4
      }}>
        <Grid container spacing={4} justifyContent='center' sx={{maxWidth: '600px'}}>
          <Grid item>
            <Typography variant='h4'>Добро пожаловать!</Typography>
          </Grid>
          <Grid item>
            <Typography>
              Спасибо за ваше приобретение продукта Aspex AI Lite. Для организации доступа к платформе нам необходимо от
              Вас получить следующую информацию:
            </Typography>
          </Grid>
          <Grid item sx={{width: '100%'}}>
            {loading && <LoadingIndicator/>}
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomTextField
                  name='TenantName'
                  label='Наименование тенанта'
                  required
                />
                <CustomTextField
                  name='TenantCompanyName'
                  label='Наименование компании'
                  required
                />
                <CustomTextField
                  name='TenantCompanyCountry'
                  label='Страна регистрации компании'
                  required
                />
                <CustomTextField
                  name='TenantCompanyAddress'
                  label='Юридический адрес компании'
                  required
                />
                <CustomTextField
                  name='TenantCompanyWebsite'
                  label='Сайт компании'
                />
                <CustomTextField
                  name='TenantCompanyTaxRegistrationCode'
                  label='ИИН Компании'
                />
                <AlertMessage/>
                <Button variant='contained' type='submit'
                        sx={{width: '100%'}}>Отправить</Button>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
      <Footer/>
    </Box>
  )
}

export default AzureCreateTenant;