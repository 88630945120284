import { useSelector } from 'react-redux'
import { RequireRedirect } from './requireRedirect'
import { Navigate } from 'react-router-dom'

const RequireAuth = ({ children }) => {

  const {
    access_token,
  } = useSelector(state => state.authReducer)

  if (!access_token) {
    return <Navigate to="/login"/>
  }

  return (
    <RequireRedirect>
      {children}
    </RequireRedirect>
  )

}

export { RequireAuth }
