import { NavLink as RouterLink } from 'react-router-dom'
import { Box, Grid, List, ListItemText } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { StyledNavItem } from '../../theme/standarts_styles'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WidgetsIcon from '@mui/icons-material/Widgets';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import AssignmentIcon from '@mui/icons-material/Assignment';

export default function NavSection ({ ...other }) {
	const { t } = useTranslation()
	const { roles } = useSelector(state => state.authReducer)

	const navConfig = [
		{
			title: t('main.routes.home'),
			path: '/',
			roles: [{ name: 'base' }],
			icon: <HomeIcon size="small"/>,
		}, {
			title: t('main.routes.myAccounts'),
			path: '/my-accounts',
			roles: [{ name: 'base' }],
			icon: <AccountBalanceIcon size="small"/>,
		}, {
			title: t('main.routes.mySubscriptions'),
			path: '/my-subscriptions',
			roles: [{ name: 'base' }],
			icon: <CategoryIcon size="small"/>,
		}, {
			title: t('main.routes.myData'),
			path: '/my-data',
			roles: [{ name: 'base' }],
			icon: <AccountBalanceWalletIcon size="small"/>,
		}, {
			title: 'Документы',
			path: '/documents',
			roles: [{ name: 'base' }],
			icon: <AssignmentIcon size="small"/>,
		}, {
			title: t('main.routes.accessControl'),
			path: '/access-control',
			roles: [{ name: 'AccessManagement' }],
			icon: <GroupOutlinedIcon size="small"/>,
		},
	]

	return (
		<Box>
			<List disablePadding sx={{ p: 1 }}>
				{navConfig.map((item) => (
					roles.some(role => item.roles?.some(r => r.name === role.name)) && (  // выводим только те элементы у которых есть совпадение между названиями roles в navConfig и authReducer
						<NavItem
							key={item.title}
							item={item}
						/>
					)
				))}
			</List>
		</Box>
	)
}

function NavItem ({ item }) {

	const { title, path, icon } = item
	const { openNav } = useSelector(state => state.serviceReducer)

	return (
		<StyledNavItem
			component={RouterLink}
			to={path}
			sx={{
				p: 1,
				color: 'layout.accent_element',
				'&.active': {
					bgcolor: 'layout.accent_element',
					color: 'layout.main',
					fontWeight: 'fontWeightBold',
					borderRadius: '8px',
				},
			}}
		>
			{openNav ?
				<>
					{icon && icon}
					<ListItemText
						disableTypography
						primary={title}
						sx={{
							ml: 2,
							// overflow: "hidden",
							// whiteSpace: 'nowrap',
							// textOverflow: 'ellipsis'
						}}
					/>
				</>
				:
				<>
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="center"
						sx={{
							width: '100%',
						}}
					>
						{icon && icon}
					</Grid>
				</>

			}


		</StyledNavItem>
	)
}
