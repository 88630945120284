import {useRoutes} from 'react-router-dom'
import DashboardLayout from './layouts/DashboardLayout'
import NotFoundPage from './pages/basePage/NotFoundPage'
import Login from './pages/basePage/login'
import Register from './pages/basePage/Register'
import AccountPage from './pages/basePage/AccountPage'
import {RequireAuth} from './hoc/requireAuth'
import {RequireRedirect} from './hoc/requireRedirect'
import CompletionRegistration from './pages/basePage/CompletionRegistration'
import AccountChangePassword from './components/Account/AccountChangePassword'
import AccessControl from './pages/AccessControl/AccessControl'
import UserData from './components/Account/UserData'
import ChangePassword from './pages/basePage/ChangePassword'
import AccessUserPage from './pages/AccessControl/AccessUserPage'
import AccessGroupsPage from './pages/AccessControl/AccessGroupsPage'
import AccessSsoSettingsPage from './pages/AccessControl/AccessSsoSettingsPage'
import LoginSuccess from './pages/basePage/LoginSuccess'
import {store} from './store/store'
import MainPage from './pages/MainPage'
import Balance from './pages/Balance/Balance'
import BalanceStatePage from './pages/Balance/BalanceStatePage'
import BalanceAccountsPage from './pages/Balance/BalanceAccountsPage'
import MyAccounts from "./pages/MyAccounts/MyAccounts";
import MyData from "./pages/MyData/MyData";
import TenantManagement from "./pages/TenantManagement/TenantManagement";
import AzureRegistration from "./pages/Azure/AzureRegistration";
import AzureChangePassword from "./pages/Azure/AzureChangePassword";
import AzureComplete from "./pages/Azure/AzureComplete";
import i18next from "i18next";
import MySubscriptions from "./pages/MySubscriptions/MySubscriptions";
import AzureLayout from "./layouts/AzureLayout";
import AzureCreateSubscription from "./pages/Azure/AzureCreateSubscription";
import AzureCreatePassword from "./pages/Azure/AzureCreatePassword";
import SubscriptionManage from "./pages/MySubscriptions/SubscriptionManage";
import SubscriptionGeneral from "./pages/MySubscriptions/SubscriptionGeneral";
import SubscriptionCharts from "./pages/MySubscriptions/SubscriptionCharts";
import SubscriptionTransactions from "./pages/MySubscriptions/SubscriptionTransactions";
import AzureCreateTenant from "./pages/Azure/AzureCreateTenant";
import MyAccountsView from "./pages/MyAccounts/MyAccountsView";
import MyAccountsAll from "./pages/MyAccounts/MyAccountsAll";
import MyAccountsAdd from "./pages/MyAccounts/MyAccountsAdd";
import Documents from "./pages/Documents/Documents";
import ClosingDocuments from "./pages/Documents/ClosingDocuments";
import PersonalDocuments from "./pages/Documents/PersonalDocuments";
import Contracts from "./pages/Documents/Contracts";
import LandingLayout from "./layouts/LandingLayout";
import LandingLeadmagnet from "./pages/Landings/Leadmagnet/LandingLeadmagnet";
import LandingWebinar from "./pages/Landings/Webinar/LandingWebinar";
import AzureChangeTariffFixed from "./pages/Azure/AzureChangeTariffFixed";
import AzureChangeTariffProrated from "./pages/Azure/AzureChangeTariffProrated";
import LandingVideo from "./pages/Landings/Video/LandingVideo";

export const getRoutes = () => {

  return (
    [
      {
        path: '/',
        locked_flags: [{name: 'CompleteRegistration'}, {name: 'ResetPassword'}],
        element: <DashboardLayout/>,
        roles: [
          {name: 'base'},
          {name: 'AccessManagement'},
          {name: 'WithoutRoles'}, // для доступа к страницам, на которые роли не нужны
        ],
        children: [
          {
            path: '',
            element: <RequireRedirect><MainPage/></RequireRedirect>,
            roles: [{name: 'base'}],
            searchText: i18next.t('main.routes.home'),
          },
          {
            path: 'tenant-management',
            element: <RequireRedirect><TenantManagement/></RequireRedirect>,
            roles: [{name: 'base'}],
            searchText: i18next.t('main.routes.accountManagement'),
          },
          {
            path: 'my-subscriptions',
            element: <RequireRedirect><MySubscriptions/></RequireRedirect>,
            roles: [{name: 'base'}],
            searchText: i18next.t('main.routes.mySubscriptions'),
          },
          {
            path: 'my-subscriptions/:subscriptionId',
            element: (
              <RequireAuth>
                <SubscriptionManage/>
              </RequireAuth>
            ),
            roles: [{name: 'base'}],
            children: [
              {
                path: 'general',
                element: (
                  <RequireAuth>
                    <SubscriptionGeneral/>
                  </RequireAuth>
                ),
                roles: [{name: 'base'}],
              },
              {
                path: 'transactions',
                element: (
                  <RequireAuth>
                    <SubscriptionTransactions/>
                  </RequireAuth>
                ),
                roles: [{name: 'base'}],
              },
              {
                path: 'charts',
                element: (
                  <RequireAuth>
                    <SubscriptionCharts/>
                  </RequireAuth>
                ),
                roles: [{name: 'base'}],
              },
            ],
          },
          {
            path: 'my-accounts',
            element: <RequireRedirect><MyAccounts/></RequireRedirect>,
            roles: [{name: 'base'}],
            searchText: i18next.t('main.routes.myAccounts'),
            children: [
              {
                path: 'all',
                element: <RequireAuth><MyAccountsAll/></RequireAuth>,
                roles: [{name: 'base'}],
              },
              {
                path: 'unpaid',
                element: <RequireAuth><MyAccountsAll/></RequireAuth>,
                roles: [{name: 'base'}],
              },
              {
                path: 'paid',
                element: <RequireAuth><MyAccountsAll/></RequireAuth>,
                roles: [{name: 'base'}],
              },
              {
                path: 'canceled',
                element: <RequireAuth><MyAccountsAll/></RequireAuth>,
                roles: [{name: 'base'}],
              },
            ],
          },
          {
            path: 'my-accounts/new',
            element: <RequireRedirect><MyAccountsAdd/></RequireRedirect>,
            roles: [{name: 'base'}],
            // searchText: i18next.t('main.routes.myAccounts'),
          },
          {
            path: 'my-accounts/:accountId',
            element: <RequireRedirect><MyAccountsView/></RequireRedirect>,
            roles: [{name: 'base'}],
            searchText: i18next.t('main.routes.myAccounts'),
          },
          {
            path: 'my-data',
            element: <MyData/>,
            roles: [{name: 'base'}],
            searchText: i18next.t('main.routes.myData'),
          },
          {
            path: 'documents',
            element: <RequireRedirect><Documents/></RequireRedirect>,
            roles: [{name: 'base'}],
            searchText: i18next.t('main.routes.balance'),
            children: [
              {
                path: 'closing-documents',
                element: <RequireAuth><ClosingDocuments/></RequireAuth>,
                roles: [{name: 'base'}],
              }, {
                path: 'personal-documents',
                element: <RequireAuth><PersonalDocuments/></RequireAuth>,
                roles: [{name: 'base'}],
              }, {
                path: 'contracts',
                element: <RequireAuth><Contracts/></RequireAuth>,
                roles: [{name: 'base'}],
              },
            ],
          },
          {
            path: 'balance',
            element: <RequireRedirect><Balance/></RequireRedirect>,
            roles: [{name: 'base'}],
            searchText: i18next.t('main.routes.balance'),
            children: [
              {
                path: 'state',
                element: <RequireAuth><BalanceStatePage/></RequireAuth>,
                roles: [{name: 'base'}],
              }, {
                path: 'accounts',
                element: <RequireAuth><BalanceAccountsPage/></RequireAuth>,
                roles: [{name: 'base'}],
              },
            ],
          }, {
            path: 'access-control',
            element: <AccessControl/>,
            roles: [{name: 'AccessManagement'}],
            searchText: i18next.t('main.routes.accessControl'),
            children: [
              {
                path: 'access-user-page',
                element: <RequireAuth><AccessUserPage/></RequireAuth>,
                roles: [{name: 'AccessManagement'}],
              }, {
                path: 'access-groups-page',
                element: <RequireAuth><AccessGroupsPage/></RequireAuth>,
                roles: [{name: 'AccessManagement'}],
              }, {
                path: 'access-sso-settings-page',
                element: <RequireAuth><AccessSsoSettingsPage/></RequireAuth>,
                roles: [{name: 'AccessManagement'}],
              },
            ],
          }, {
            path: 'account',
            element: <AccountPage/>,
            roles: [{name: 'base'}],
            searchText: i18next.t('main.routes.profile'),
            children: [
              {
                path: 'user-data',
                element: <RequireAuth><UserData/></RequireAuth>,
                roles: [{name: 'base'}],
              }, {
                path: 'user-change-password',
                element: <RequireAuth><AccountChangePassword/></RequireAuth>,
                roles: [{name: 'base'}],
              },
            ],
          }, {
            path: 'primary-registration',
            locked_flags: [{name: 'CompleteRegistration'}],
            element: <RequireAuth><CompletionRegistration/></RequireAuth>,
            roles: [{name: 'base'}],
          }, {
            path: 'change-password',
            locked_flags: [{name: 'ResetPassword'}],
            element: <RequireAuth><ChangePassword/></RequireAuth>,
            roles: [{name: 'base'}],
          }, {
            path: 'login', element: <RequireRedirect><Login/></RequireRedirect>,
            roles: [{name: 'WithoutRoles'}],
          }, {
            path: 'auth/success', element: <RequireRedirect><LoginSuccess/></RequireRedirect>,
            roles: [{name: 'WithoutRoles'}],
          }, {
            path: 'register', element: <RequireRedirect><Register/></RequireRedirect>,
            roles: [{name: 'WithoutRoles'}],
          }, {
            path: 'reset-password', element: <RequireRedirect> <Register/> </RequireRedirect>,
            roles: [{name: 'WithoutRoles'}],
          },
          {
            path: 'azure-subscription', element: <RequireRedirect> <AzureRegistration/> </RequireRedirect>,
            roles: [{name: 'WithoutRoles'}],
          }, {
            path: 'azure-change-password',
            element: <RequireRedirect> <AzureChangePassword/> </RequireRedirect>,
            roles: [{name: 'WithoutRoles'}],
          }, {
            path: 'azure-complete',
            element: <RequireRedirect> <AzureComplete/> </RequireRedirect>,
            roles: [{name: 'WithoutRoles'}],
          }, {
            path: '*',
            locked_flags: [{name: 'CompleteRegistration'}, {name: 'ResetPassword'}],
            element: <NotFoundPage/>,
            roles: [
              {name: 'base'},
              {name: 'AccessManagement'},
              {name: 'WithoutRoles'},
            ],
          },
        ],
      },
      {
        path: 'azure',
        locked_flags: [{name: 'CompleteRegistration'}, {name: 'ResetPassword'}],
        element: <AzureLayout/>,
        roles: [{name: 'WithoutRoles'}],
        children: [
          {
            path: 'create-tenant',
            element: <RequireRedirect><AzureCreateTenant/></RequireRedirect>,
            roles: [{name: 'WithoutRoles'}],
          },
          {
            path: 'create-subscription',
            element: <RequireRedirect><AzureCreateSubscription/></RequireRedirect>,
            roles: [{name: 'WithoutRoles'}],
          },
          {
            path: 'create-password',
            element: <RequireRedirect><AzureCreatePassword/></RequireRedirect>,
            roles: [{name: 'WithoutRoles'}],
          },
          {
            path: 'change-tariff-fixed/:subscriptionId',
            element: <RequireRedirect><AzureChangeTariffFixed/></RequireRedirect>,
            roles: [{name: 'WithoutRoles'}],
          },
          {
            path: 'change-tariff-prorated/:subscriptionId',
            element: <RequireRedirect><AzureChangeTariffProrated/></RequireRedirect>,
            roles: [{name: 'WithoutRoles'}],
          },
          // {
          //   path: 'subscription',
          //   element: <RequireRedirect><Azure/></RequireRedirect>,
          //   roles: [{name: 'WithoutRoles'}],
          // },
          // {
          //   path: 'change-password',
          //   element: <RequireRedirect><AzureChangePassword/></RequireRedirect>,
          //   roles: [{name: 'WithoutRoles'}],
          // },
          // {
          //   path: 'complete',
          //   element: <RequireRedirect><AzureComplete/></RequireRedirect>,
          //   roles: [{name: 'WithoutRoles'}],
          // },
        ]
      },
      {
        path: 'marketing',
        locked_flags: [{name: 'CompleteRegistration'}, {name: 'ResetPassword'}],
        element: <LandingLayout/>,
        roles: [{name: 'WithoutRoles'}],
        children: [
          {
            path: 'lm',
            // element: <LandingLeadmagnet/>,
            roles: [{name: 'WithoutRoles'}],
            children: [
              {
                path: 'aiedu0524',
                element: <LandingLeadmagnet/>,
                roles: [{name: 'WithoutRoles'}]
              }
            ]
          },
          {
            path: 'wb',
            // element: <LandingWebinar/>,
            roles: [{name: 'WithoutRoles'}],
            children: [
              {
                path: 'aiedu130624',
                element: <LandingWebinar/>,
                roles: [{name: 'WithoutRoles'}],
              },
              {
                path: 'aiedu130624',
                roles: [{name: 'WithoutRoles'}],
                children:[
                  {
                    path: 'recording',
                    element: <LandingVideo/>,
                    roles: [{name: 'WithoutRoles'}]
                  }
                ]

              }
            ]
          },
        ]
      },
    ]
  )
}

export const getFilteredRoutes = () => {
  const {locked_flags, roles} = store.getState().authReducer
  const routes = getRoutes()

  const filterRoutesRecursively = (route) => {
    if (route.roles && route.roles.length > 0) {
      const shouldIncludeWithoutRoles = roles === null && route.roles?.some(r => r.name === 'WithoutRoles')  // отображаем рут если роль пользователя отсутствует и в route есть роль 'WithoutRoles'
      const shouldIncludeWithRoles = route.roles?.some(r => roles?.some(userRole => r.name === userRole.name))  // отображаем рут если пользователь имеем хотя бы одну роль, которая совпадает с route ролью
      const hasMatchingLockedFlag = route.locked_flags?.some(flag => locked_flags?.name === flag.name)  // отображаем рут если пользователь имеем locked_flags, который совпадает с route locked_flags

      if ((shouldIncludeWithoutRoles || shouldIncludeWithRoles) && (!locked_flags || hasMatchingLockedFlag)) {
        if (Array.isArray(route.children)) {
          const filteredChildren = route.children.map(childRoute => filterRoutesRecursively(childRoute)).filter(Boolean)
          return {...route, children: filteredChildren}
        }
        return route
      }
    }
    return null
  }

  return routes.map(route => filterRoutesRecursively(route)).filter(Boolean)
}

export default function Router() {
  const filteredRoutes = getFilteredRoutes()
  // console.log('>>>>>>>>>>>>>>>>>>>>>>filteredRoutes', filteredRoutes)
  return useRoutes(filteredRoutes)
}
