import React, { useEffect } from 'react'
import {Box, Button, Card, Container, Grid, Typography} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {StyledContent, StyledRoot, StyledRootUpMD} from '../../theme/standarts_styles'
import { useActions } from '../../hook/useActions'
import {useSelector} from "react-redux";
import ChangePasswordForm from "../forms/ChangePasswordForm";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const { isDarkTheme } = useSelector(state => state.serviceReducer)

  const {
    setOpenNav,
  } = useActions()

  useEffect(() => {
    setOpenNav(false)
  }, [])

  return (
      <div
          style={{
              width: '100%',
              height: '100%',
              // backgroundImage: `url("/assets/illustrations/image_404.png")`
          }}
      >
          <StyledRoot>
              <Container maxWidth="lg">
                  <StyledContent width={900}>
                      <Card
                          sx={{
                              p: 4,
                              backgroundColor: 'layout.main'
                          }}
                      >
                          <Grid
                              p={3}
                              container
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                          >
                              <Grid item>
                                  <Typography
                                      color="layout.element"
                                      fontSize="200pt"
                                      align="center"
                                      fontWeight="700"
                                      lineHeight="1"
                                  >
                                      404
                                  </Typography>
                                  <Typography
                                      color="layout.element"
                                      variant="h2"
                                      align="center"
                                  >
                                      {t('src.pages.NotFoundPage.pageNotFoundMessage')}
                                  </Typography>
                              </Grid>
                              <Grid item>
                                  <Button
                                      size="large"
                                      variant="contained"
                                      href="/"
                                      color="primary"
                                      sx={{
                                          mt: 5,
                                          borderRadius: '30px',
                                      }}
                                      endIcon={<HomeOutlinedIcon/>}
                                  >
                                      {t('src.pages.NotFoundPage.toGeneral')}
                                  </Button>
                              </Grid>
                          </Grid>
                      </Card>
                  </StyledContent>
              </Container>
          </StyledRoot>
      </div>

  )
}

export default NotFoundPage

