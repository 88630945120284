import React, {useEffect, useState} from 'react'
import {Grid, IconButton, InputAdornment, Link} from '@mui/material'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import {$customHost} from '../../http'
import {useTranslation} from 'react-i18next'
import {useActions} from '../../hook/useActions'
import AlertMessage from '../Alert/AlertMessage'
import {FormProvider, useForm} from 'react-hook-form'
import CustomTextField from "../Fields/CustomTextField";
import jwt_decode from 'jwt-decode';
import {authUrl} from "../../http/baseRoute";

export function LoginForm() {
  const {t} = useTranslation()

  const {
    setToken,
    setInfoAlertMessage,
    resetAllAlertMessage,
  } = useActions()

  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const methods = useForm({
    mode: 'onBlur',
  })

  const {
    handleSubmit,
  } = methods;

  const handleClickShowPassword = () => setShowPassword(prevState => !prevState)

  const onSubmit = async (data) => {
    try {
      const response = await $customHost.post(`${authUrl}/auth/sign-in`, data)

      const {
        data: {access_token},
        headers: {date},
      } = response;

      const utcResponseTime = new Date(date)
      const startTime = utcResponseTime.getTime() //localTimeMilliseconds

      const decodedToken = jwt_decode(access_token);
      const tokenLifetime =
        decodedToken.exp * 1000 - (Date.parse(date) || Date.now());

      setToken({
        ...response.data, startTime, tokenLifetime, roles: [{name: 'base'},
          {name: 'AccessManagement'},
          {name: 'WithoutRoles'}],
        groups: ['Администратор', 'Пользователь'],
        status: "Active",
        locked_flags: null
      })
    } catch (e) {
      if (e.response.status === 403) {
        setInfoAlertMessage(t('src.sections.auth.login.wrongLoginPassword'))
      }
      if (e.response.status === 401 && e.response.data.detail === 'Temporary password has expired') {
        setInfoAlertMessage(t('src.sections.auth.login.passwordExpired',
          'Срок действия временного пароля истек. Пройдите процедуру сброса пароля и получите новый.'))
      }
    }
  }

  useEffect(() => {
    resetAllAlertMessage()
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 2}}>
          <CustomTextField
            name='email'
            label={t('src.sections.auth.login.login')}
            required
            rules={{
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: t('main.another.wrongInput'),
              },
            }}
            autoComplete="email"
            autoFocus
          />
          <CustomTextField
            name='password'
            label={t('src.sections.auth.login.password')}
            required
            rules={{
              pattern: {
                value: /^[!-~]{1,32}$/,
                message: t('main.another.wrongInput'),
              },
            }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>,
            }}
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mb: 1}}
            color="secondary"
          >
            {t('src.sections.auth.login.LoginForm.goInto')}
          </Button>
          <Grid container sx={{mb: 2}}>
            <Grid item xs>
              <Link href="/reset-password" variant="body2" color="layout.element">
                {t('src.pages.Register.forgotPassword')}
              </Link>
            </Grid>
          </Grid>
          <AlertMessage/>
        </Box>
      </FormProvider>
    </>
  )
}
