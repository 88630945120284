import {createSlice} from "@reduxjs/toolkit";


const initialState = {};

const CompanySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    logOut() {
      return initialState
    },
    setCompanyData(state, {payload}) {
      return {...state, ...payload}
    },
  },
});

export const {actions: companyActions, reducer: companyReducer} = CompanySlice
export default CompanySlice.reducer;
