import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import RemoteDynamicTabs from '../../components/Tabs/RemoteDynamicTabs'
import { TitleStack } from '../../theme/standarts_styles'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const AccessControl = () => {
	const { t } = useTranslation()
	const location = useLocation()

	const tabs = [
		{ label: t('src.pages.AccessControl.tab1'), path: 'access-user-page' },
		{ label: t('src.pages.AccessControl.tab2'), path: 'access-groups-page' },
		{ label: t('src.pages.AccessControl.tab3'), path: 'access-sso-settings-page' },
	]

	// Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
	if (!tabs.some(tab => location.pathname.endsWith(tab.path))) {
		let redirectPath = location.pathname;
		if (!location.pathname.endsWith('/')) {
			redirectPath += '/';
		}
		redirectPath += tabs[0].path;
		return <Navigate to={redirectPath} />;
	}

	return (
		<Container>
			<TitleStack>
				<Typography variant="h4">
					{t('src.pages.AccessControl.head')}
				</Typography>
			</TitleStack>
			<RemoteDynamicTabs tabs={tabs}/>
			<Outlet/> {/* Отображение содержимого дочерних маршрутов */}
		</Container>
	)
}

export default AccessControl