import {Box, Button, Card, CardContent, CardMedia, Grid, Paper, Typography} from "@mui/material";
import CustomTextField from "../../../components/Fields/CustomTextField";
import React, {useEffect, useState} from "react";
import {FormProvider, useForm, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {$authHost} from "../../../http";
import robotImage from "./assets/images/robot.png";
import robotImageDouble from "./assets/images/robotDouble.webp";
import logoColor from "./assets/images/logo_color.png";
import logoBlack from "./assets/images/logoBlack.png";
import logoGT from "./assets/images/logoGT.png";
import logo from "./assets/images/logo.png";
import Image from './assets/images/img.jpg'
import photoVT from "./assets/images/VT_2.jpg";
import photoYG from "./assets/images/YG_2.png";
import background from "./assets/images/background.png";
import microsoft from "./assets/images/microsoft.png";
import logoWhite from "./assets/images/logo_white.png";
import chatbotImage from "./assets/images/chatbot.png";
import chipImage from "./assets/images/chip.png";
import emailImage from "./assets/images/email.png";
import bllogo from '../Leadmagnet/assets/images/blLogo.png'
import Stack from "@mui/material/Stack";
import LoadingIndicator from "../../../components/LoadingIndicator";
import CustomAutocomplete from "../../../components/Fields/CustomAutocomplete";
import "../land_styles.css";
import bookImage from "../Leadmagnet/assets/images/2Black.png";
import {Item_block} from "../../../theme/standarts_styles";
import CustomMuiTelInput from "../../../components/Fields/CustomMuiTelInput";
import { matchIsValidTel } from 'mui-tel-input'
import axios from "axios";


const LandingWebinar = () => {

    document.title = "Вебинар ASPEX AI + GETCOURSE"
    document.description = "Первый в СНГ вебинар посвященный интеграции искусственного интеллекта в платформу GETCOURSE";
    document.image = Image;

    const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

    const [isTablet, setIsTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

  const methods = useForm({
    mode: 'onBlur',
  });




    const {
    handleSubmit
  } = methods;

  const onSubmit = async (data) => {
    setError(null);
    setLoading(true);
    try {
      await axios.post(`https://my-bk-dev.aspex.cloud/mailchimp/webinar/subscribe`, data);
      setSuccess(true);
    } catch (e) {
      setError('Произошла ошибка. Пожалуйста, проверьте введенные данные и повторите попытку.')
    } finally {
      setLoading(false);
    }
  }

    const onResizeHandler = () => {
        const isTablet = window.innerWidth >= 0 && window.innerWidth <= 900;
        const isMobile = window.innerWidth <= 600;
        setIsTablet(isTablet );
        setIsMobile(isMobile );
    };

  const speakers = [
    {
      photo: photoYG,
      name: 'Юлия Гайнутдинова',
      description: 'Основатель и генеральный директор компании ASPEX - ведущего профессионального IT интегратора и эксперта в области BI-аналитики со статусом Золотого партнера Microsoft',
    },
    {
      photo: photoVT,
      name: 'Вадим Тигин',
      description: 'Технический директор компании ASPEX, директор продукта в области ИИ ASPEX AI и руководитель направления Aspex AI Online Education',
    },
  ]

  const benefits = [
    {
      logo: chatbotImage,
      title: 'ЗАДАВАЙТЕ ВОПРОСЫ ЭКСПЕРТАМ',
      description: 'Получите ответы на любые вопросы от технического и генерального директоров компании Aspex, золотого партнера Microsoft с многолетним опытом и более 370 успешных проектов для бизнеса.',
    },
    {
      logo: chipImage,
      title: 'БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ',
      description: 'Уникальная возможность получить бесплатную консультацию с нашими экспертами о внедрении ИИ конкретно в ваш бизнес!',
    },
    {
      logo: emailImage,
      title: 'СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ',
      description: 'Только для первых 20 участников - эксклюзивное предложение на внедрение и поддержку ИИ!',
    },
  ]


    const Setup = () => {
        window.addEventListener("resize", onResizeHandler, false);
    };


    const Cleanup = () => {
        window.removeEventListener("resize", onResizeHandler, false);
    };

    useEffect(() => {

        onResizeHandler();

        Setup();

        return () => {

            Cleanup();
        };
    }, []);


  return (
      <Grid container direction='column' justifyContent='center' alignItems='center' sx={{backgroundColor: '#fff'}} >
              {/*<Grid container width="100wh" spacing={0} direction='row' justifyContent='center' alignItems='center'*/}
              {/*      sx={{*/}
              {/*        width: '100wh',*/}
              {/*        pt: "30px",*/}
              {/*        pb: "30px",*/}
              {/*        px: {xl: '100px', lg:'100px', md:'50px', sm:'40px', xs:'20px'}*/}
              {/*      }}*/}
              {/*>*/}
              {/*  <Box*/}
              {/*      component="img"*/}
              {/*      src={bllogo}*/}
              {/*      sx={{height: 20}}*/}
              {/*  />*/}
              {/*</Grid>*/}

              <Grid container
                    sx={{
                        backgroundColor: '#6e6cd9',
                        py: {xl: '50px', lg:'50px', md:'50px', sm:'20px', xs:'40px'},
                        px: {xl: '100px', lg:'100px', md:'50px', sm:'40px', xs:'25px'},
                    }}
              >
                  <Typography sx={{color: '#ffffff', fontSize: {xl: '50px', lg:'50px', md:'50px', sm:'40px', xs:'40px'}, fontWeight: 700}}
                              align='left'>
                      ПЕРВЫЙ В СНГ ВЕБИНАР<br/>
                  </Typography>
                  <Typography sx={{color: '#ffffff', fontSize: {xl: '30px', lg:'30px', md:'30px', sm:'20px', xs:'22px'}, fontWeight: 600}}
                              align='left'>
                      ПОСВЯЩЕННЫЙ ИНТЕГРАЦИИ ИСКУССТВЕННОГО ИНТЕЛЛЕКТА В ПЛАТФОРМУ GETCOURSE!
                  </Typography>
              </Grid>
              <Grid container spacing={0} sx={{width: '100%'}}>

                  {
                      isTablet === false ?
                          <>
                              <Grid
                                  item
                                  xs={0} xl={6} lg={6} md={4} sm={0}
                                  pt={12} pb={{xs: 12, md: 0}}
                                  sx={{
                                      height: '80vh',
                                      backgroundImage: `url(${robotImageDouble})`, backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover", backgroundPosition: 'center', position: 'relative'
                                  }}
                              >
                              </Grid>
                              <Grid
                                  item
                                  xs={0} xl={6} lg={6} md={8} sm={0}
                                  sx={{
                                      width: '100%',
                                      display: 'flex',
                                      px: {xl: '0px', lg:'0px', md:'50px', sm:'15px', xs:'15px'}
                                  }}
                                  alignItems='center' direction='column' justifyContent='center'
                              >
                                  <Grid
                                      item
                                      xs={12}
                                      mb={2}
                                      mt={2}
                                      sx={{display: 'flex'}}
                                      alignItems='center' direction='column' justifyContent='center'
                                  >
                                      <Stack spacing={4} sx={{alignItems:'center', marginBottom: "20px"}}>
                                          <img src={logoBlack} alt="" style={{width: '400px', height: 'auto'}}/>
                                          <Typography
                                              px={2}
                                              style={{fontSize: '40px', fontWeight: 700, lineHeight: 0}}
                                          >
                                              +
                                          </Typography>
                                          <img src={logoGT} alt="" style={{width: '400px', height: 'auto'}}/>
                                      </Stack>
                                      <Grid item mt={5}>
                                          <Typography sx={{fontSize: '50px', fontWeight: 700, textTransform: 'uppercase'}}
                                                      align='center'>СОЗДАЙТЕ ПРИБЫЛЬНУЮ<br/> ОНЛАЙН-ШКОЛУ</Typography>
                                      </Grid>
                                  </Grid>
                              </Grid>
                          </>
                          :
                          <Grid item xs={12}
                                justifyContent="flex-end"
                                alignItems="center"
                                direction="column"
                                pb={4}
                                sx={{
                                    backgroundImage: `url(${robotImageDouble})`, backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover", backgroundPosition: 'bottom', position: 'relative', height: '90vh', display: 'flex'
                                }}
                          >
                              <Grid
                                  container
                                  sx={{
                                      width: '100%',
                                      backgroundColor: 'rgba(255,255,255, 1)'
                                  }}
                                  direction='column' alignItems='center' py={1} px={4}>
                                  <Stack spacing={3} sx={{alignItems:'center', marginBottom: "10px", marginTop: "15px"}}>
                                      <img src={logoBlack} className="black_logo_top" alt=""/>
                                      <Typography
                                          px={2}
                                          style={{fontSize: '40px', fontWeight: 600, lineHeight: 0}}
                                      >
                                          +
                                      </Typography>
                                      <img src={logoGT} alt="" className="gt_logo_top"/>
                                  </Stack>
                                  <Grid item>
                                      <Typography sx={{fontSize:  {xl: '50px', lg:'50px', md:'50px', sm:'40px', xs:'30px'}, fontWeight: 700, textTransform: 'uppercase'}}
                                                  align='center'>СОЗДАЙТЕ ПРИБЫЛЬНУЮ<br/> ОНЛАЙН-ШКОЛУ</Typography>
                                  </Grid>
                              </Grid>

                          </Grid>

                  }

              </Grid>

              <Grid
                  container
                  // direction="column"
                  spacing={3}
                  alignItems='center' justifyContent='center'
                  sx={{
                      width: '100%',
                      // backgroundColor:'#6e6cd9',
                      px: {xl: '160px', lg:'160px', md:'10px', sm:'40px', xs:'20px'},
                      py: {xl: '70px', lg:'70px', md:'100px', sm:'80px', xs:'80px'}
                  }}
              >
                  <Typography align="center" sx={{width: '100%', fontSize: '40px', fontWeight: 700, textTransform: 'uppercase'}}>
                      ВЫ узнаете
                  </Typography>
                  <Stack
                      sx={{py:3}}
                      spacing={{ xs: 1, sm: 2, md:4, lg:5, xl:5 }}
                      direction="row"
                      useFlexGap
                      flexWrap="wrap"
                      justifyContent="center"
                      alignItems="center"
                  >
                      <Paper elevation={3} sx={{padding: '12px'}}>
                          <Item_block sx={{fontSize: {xl: '24px', lg:'24px', md:'22px', sm:'20px', xs:'16px'}}}>
                              <span style={{fontWeight: 500}}>Как эффективно использовать ИИ</span> <br/>для создания прибыльной онлайн-школы
                          </Item_block>
                      </Paper>
                      <Paper sx={{padding: '12px'}} elevation={3}>
                          <Item_block sx={{fontSize: {xl: '24px', lg:'24px', md:'22px', sm:'20px', xs:'16px'}}}>
                              <span style={{fontWeight: 500}}> Варианты решений основных проблем</span> <br/>в организации онлайн-обучения с помощью ИИ
                          </Item_block>
                      </Paper>
                      <Paper sx={{padding: '12px'}} elevation={3}>
                          <Item_block sx={{fontSize: {xl: '24px', lg:'24px', md:'22px', sm:'20px', xs:'16px'}}}>
                              Прозрачные тарифы и<span style={{fontWeight: 500}}> преимущества готового решения</span><br/> с быстрым внедрением
                          </Item_block>
                      </Paper>
                      <Paper sx={{padding: '12px'}} elevation={3}>
                          <Item_block sx={{fontSize: {xl: '24px', lg:'24px', md:'22px', sm:'20px', xs:'16px'}}}>
                              <span style={{fontWeight: 500}}>  Реальные кейсы и примеры внедрения ИИ</span><br/> в образовательные процессы
                          </Item_block>
                      </Paper>
                      <Paper sx={{padding: '12px'}} elevation={3}>
                          <Item_block sx={{fontSize: {xl: '24px', lg:'24px', md:'22px', sm:'20px', xs:'16px'}}}>
                              <span style={{fontWeight: 500}}> Возможности и функции Aspex AI Lite</span> для оптимизации обучения <br/>и результат интеграции в Getcourse
                          </Item_block>
                      </Paper>
                  </Stack>
              </Grid>


              <Grid container spacing={0} direction='row' justifyContent='center' alignItems='center'
                    sx={{
                        width: '100%',
                        // px: {xl: '100px', lg:'100px', md:'50px', sm:'40px', xs:'20px'},
                        // py: {xl: '50px', lg:'50px', md:'50px', sm:'20px', xs:'0px'},
                        // maxWidth:'false'
                    }}
              >

                  <Grid
                      item
                      xs={12} xl={6} lg={6} md={12} sm={12}
                      alignItems='center' justifyContent='center' direction="column"
                      sx={{
                          display: 'flex',
                          pb: {xl: '0px', lg:'0px', md:'50px', sm:'50px', xs:'50px'},
                          px: {xl: '0px', lg:'15px', md:'50px', sm:'15px', xs:'15px'},
                      }}

                  >
                      <Typography align="center" className="text_about" mb={2}
                                  sx={{fontWeight: 500, textTransform: 'uppercase'}}
                      >
                          ПРИЕМУЩЕСТВА ДЛЯ УЧАСТНИКОВ
                      </Typography>
                      <Stack sx={{maxWidth: '700px'}} spacing={5} py={2}>
                          {benefits.map(benefit => (
                              <Box sx={{display: 'flex', alignItems: 'center'}}>
                                  <img src={benefit.logo} alt="" style={{width: '60px'}}/>
                                  <div>
                                      <Typography className='dot_text' sx={{fontWeight: 500, paddingLeft: '22px', marginBottom: '10px'}}>
                                          {benefit.title}
                                      </Typography>
                                      <Typography className='dot_text' sx={{paddingLeft: '22px'}}>
                                          {benefit.description}
                                      </Typography>
                                  </div>

                              </Box>
                          ))}
                      </Stack>
                  </Grid>
                  <Grid item xs={12} xl={6} lg={6} md={12} sm={12}
                        sx={{
                            display: 'flex',
                            backgroundColor:'#6e6cd9',
                        }}
                        direction="column"
                        alignItems='center' justifyContent='center'
                        py={{xs: 8}}
                        px={{xs: 4}}
                  >
                      <Typography align="center" className="text_about"  mb={2}
                                  sx={{fontWeight: 700, textTransform: 'uppercase', color:'#fff'}}
                      >
                          Участвуйте в нашем эксклюзивном вебинаре абсолютно бесплатно!
                      </Typography>
                      <Typography align="center" className="dot_text"
                                  sx={{fontWeight: 500, color:'#fff'}}
                      >
                          Оставьте нам свои контакты, чтобы получить ссылку на Zoom по почте
                      </Typography>
                      <Typography align="center" className="dot_text"
                                  sx={{fontWeight: 500, color:'#fff'}}
                      >
                          Узнайте все секреты успеха с ИИ!
                      </Typography>
                      <Box sx={{maxWidth: '700px', position: 'relative'}} pt={4}>
                          {success ? (
                              <Box sx={{height: '324px', display: 'flex', alignItems: 'center'}}>
                                  <Typography variant='h4' color="#ffffff">
                                      Вы успешно зарегистрировались на вебинар! Пожалуйста, проверьте свою электронную почту — мы уже отправили вам письмо со ссылкой на вебинар. С нетерпением ждем встречи с вами!
                                  </Typography>
                              </Box>
                          ) : (
                              <Card sx={{padding: '25px', marginBottom: '20px'}}>
                                  <FormProvider {...methods}>
                                      {loading && <LoadingIndicator/>}
                                      <form onSubmit={handleSubmit(onSubmit)}>
                                          <CustomTextField
                                              name='first_name'
                                              label='Ваше имя'
                                              required
                                          />
                                          <CustomTextField
                                              name='email'
                                              label='Электронная почта'
                                              rules={{
                                                  required: t('main.another.requiredField'),
                                                  pattern: {
                                                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                      message: t('main.another.wrongInput'),
                                                  },
                                              }}
                                              required
                                          />
                                          <CustomMuiTelInput
                                              name="phone"
                                              label='Телефон'
                                              rules={{
                                                  required: t('main.another.requiredField'),
                                                  validate: {
                                                      validPhone: (phone) => {
                                                          if (!phone) {
                                                              return true;
                                                          } else if (!matchIsValidTel(phone)) {
                                                              return 'Некорректный номер телефона';
                                                          }
                                                          return true; // Возвращает true, если проверка прошла успешно
                                                      },
                                                  },
                                              }}
                                              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
                                              size="small"
                                          />
                                          <CustomTextField
                                              name='company_name'
                                              label='Название вашей школы'
                                              required
                                          />
                                          <CustomAutocomplete
                                              name='role'
                                              label='Ваша роль'
                                              options={['Основатель',
                                                  'Продюсер',
                                                  'Проджект-менеджер',
                                                  'Куратор',
                                                  'Руководитель отдела кураторов',
                                                  'Педагог']}
                                              required
                                              freeSolo
                                          />
                                          <Button variant='contained' type='submit' color='primary'
                                                  sx={{width: '100%'}}>Зарегистрироваться бесплатно</Button>
                                          {error && (
                                              <Typography pt={2} variant='body2' color='error'>{error}</Typography>
                                          )}
                                          <Typography variant="body1" align="center" pt={2}>13.06.2024 19:00 МСК</Typography>
                                      </form>
                                  </FormProvider>
                              </Card>
                          )}
                      </Box>

                  </Grid>
              </Grid>

              {/*<Grid item xs={12} md={6} px={4} py={2} sx={{display: 'flex'}} direction='column' alignItems='center'>*/}
              {/*    */}
              {/*    */}
              {/*    <Typography sx={{fontSize: '19px', fontWeight: 500, fontStyle: 'italic'}} pt={2}>06.06.2024 19:00*/}
              {/*        МСК</Typography>*/}
              {/*</Grid>*/}

              <Grid
                  container
                  direction="column"
                  alignItems='center' justifyContent='center'
                  sx={{
                      width: '100%',
                      // backgroundColor:'#6e6cd9',
                      px: {xl: '100px', lg:'100px', md:'50px', sm:'40px', xs:'20px'},
                      py: {xl: '50px', lg:'50px', md:'50px', sm:'20px', xs:'40px'}
                  }}
              >
                  <Typography sx={{fontSize: '40px', fontWeight: 700, textTransform: 'uppercase'}}>
                      СПИКЕРЫ
                  </Typography>
                  <Box sx={{display: 'flex'}} my={3} alignItems='center'>
                      <Box>
                          <img src={microsoft} style={{width: 'auto', height: '50px'}}/>
                      </Box>
                  </Box>
                  <Stack direction={{ xs: "column", xl:"row", lg: 'row'}} spacing={4}>
                      {speakers.map(speaker => (
                          <>
                              {
                                  isMobile === true ?
                                      <Card sx={{ width: {xl:'calc(100%/2)', lg:'calc(100%/2)',xs:'100%' }}}>
                                          <CardMedia
                                              component="img"
                                              sx={{ width: '100%', height: 'auto'}}
                                              image={speaker.photo}
                                          />
                                          <CardContent>
                                              <Typography component="div" sx={{fontSize: '28px'}}>
                                                  {speaker.name}
                                              </Typography>
                                              <Typography sx={{fontSize: '18px'}} color="text.secondary" >
                                                  {speaker.description}
                                              </Typography>
                                          </CardContent>
                                      </Card>
                                      :
                                      <Card sx={{ display: 'flex', width: {xl:'calc(100%/2)', lg:'calc(100%/2)',xs:'100%' }}}>
                                          <CardMedia
                                              component="img"
                                              sx={{ width: 150, height: 'auto'}}
                                              image={speaker.photo}
                                          />
                                          <Box sx={{ display: 'flex', flexDirection:  'column' }}>
                                              <CardContent sx={{ flex: '1 0 auto' }}>
                                                  <Typography component="div" sx={{fontSize: '28px'}}>
                                                      {speaker.name}
                                                  </Typography>
                                                  <Typography sx={{fontSize: '18px'}} color="text.secondary" component="div">
                                                      {speaker.description}
                                                  </Typography>
                                              </CardContent>
                                          </Box>
                                      </Card>
                              }
                          </>



                          // <Box sx={{display: 'flex'}}>
                          //   <img src={speaker.photo} alt="" style={{width: '110px', height: '110px'}}/>
                          //   <Stack pl={4}>
                          //     <Typography sx={{fontSize: '28px', color: '#ffffff'}}>
                          //       {speaker.name}
                          //     </Typography>
                          //     <Typography sx={{fontSize: '14px', fontStyle: 'italic', color: '#ffffff'}} pl={2}>
                          //       {speaker.description}
                          //     </Typography>
                          //   </Stack>
                          // </Box>
                      ))}
                  </Stack>

              </Grid>
              <Grid container width="100wh" direction='column' justifyContent='center' alignItems='center'
                    sx={{
                        width: '100wh',
                        backgroundColor: '#ececec',
                        pt: "30px",
                        px: {xl: '100px', lg:'100px', md:'50px', sm:'40px', xs:'20px'}
                    }}
              >
                  <Box
                      component="img"
                      src={bllogo}
                      sx={{
                          height: 20,
                          mb: 4,
                          mt:3
                      }}
                  />
                  <Typography variant='h6' mb={1} align="center">
                      Нужна помощь с ИИ? Свяжитесь с нами
                  </Typography>
                  <Typography variant='h6' mb={1} >
                      info@aspex.kz
                  </Typography>
                  <Typography variant='body1' mb={5} align="center" >
                      Copyright ASPEX Group 2016-2024. All rights reserved.
                  </Typography>
              </Grid>
          </Grid>
  )
}

export default LandingWebinar;