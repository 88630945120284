import {Button, Card, Divider, Typography} from "@mui/material";
import CustomViewField from "../../components/CustomViewField";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Container from "@mui/material/Container";
import {TitleStackButton} from "../../theme/standarts_styles";
import {useActions} from "../../hook/useActions";
import {$authHost} from "../../http";
import React, {useEffect, useState} from "react";
import LoadingIndicator from "../../components/LoadingIndicator";

const MyAccountsView = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const {setMyAccount} = useActions();
  const {account} = useSelector(
    (state) => state.accountsReducer,
  );

  const {accountId} = useParams();

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/invoice/get_one/${accountId}`);
      setMyAccount(response.data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleBack = () => {
    navigate('/my-accounts');
  };

  return (
    <Container>
      {loading && <LoadingIndicator/>}
      <TitleStackButton>
        <Typography variant='h4'>Счет №{account?.InvoiceID}</Typography>
        <Button
          variant='outlined'
          onClick={() => {
          }}
        >
          Скачать PDF
        </Button>
      </TitleStackButton>
      <Typography>Сформирован {dayjs(account?.InvoiceDate).format('DD.MM.YYYY')}</Typography>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{pt: 2, pb: 1}}>
        <Typography variant='h6'>Назначение и сумма счета</Typography>
        <Stack direction='row' spacing={2}>
          <Button variant='contained' onClick={() => {
          }}>
            Оплатить
          </Button>
          <Button
            variant='text'
            onClick={() => {
            }}
          >
            Отменить
          </Button>
        </Stack>
      </Stack>
      <Card sx={{mb: 2, p: 3}}>
        <Typography>Статус оплаты - {dayjs(account?.InvoiceDate).format('DD.MM.YYYY')}</Typography>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{pt: 1}}>
          <Typography sx={{fontSize: 18}}>Название услуги</Typography>
          <Typography>{account?.TotalAmount}</Typography>
        </Stack>
      </Card>
      <Typography variant='h6' sx={{pb: 2}}>Статус оплаты</Typography>
      <Card sx={{mb: 2, p: 3}}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{pb: 1}}>
          <Typography sx={{fontSize: 18}}>ИТОГО</Typography>
          <Typography>{account?.TotalAmount}</Typography>
        </Stack>
        <Divider/>
        <Stack sx={{pt: 2}}>
          <CustomViewField label='ID транзакции' text={account?.InvoiceID}/>
          <CustomViewField label='Дата транзакции' text={dayjs(account?.InvoiceDate).format('DD.MM.YYYY')}/>
          <CustomViewField label='Оплачено через' text={account?.PaymentMethodName}/>
        </Stack>
      </Card>
      <Stack direction='row' spacing={2} sx={{pb: 2}}>
        {/*<Button variant='contained' onClick={handleEdit}>*/}
        {/*  Редактировать*/}
        {/*</Button>*/}
        <Button
          variant='contained'
          onClick={handleBack}
        >
          Назад
        </Button>
      </Stack>
    </Container>
  )
}

export default MyAccountsView;