import React, { Suspense } from 'react'
import { AuthProvider } from './hoc/authProvider'
import { LoadProvider } from './hoc/loadProvider'
import ThemeProvider from './theme'
import { HelmetProvider } from 'react-helmet-async'
import LoadingSpinner from './components/modals/loadingSpinner'
import Router from './routes'

function App () {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <Suspense fallback={<LoadingSpinner/>}>
          <AuthProvider>
            <LoadProvider>
              <Router/>
            </LoadProvider>
          </AuthProvider>
        </Suspense>
      </ThemeProvider>
    </HelmetProvider>

  )
}

export default App
