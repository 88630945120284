import {Button, Grid} from "@mui/material";
import CustomViewField from "../../components/CustomViewField";
import Stack from "@mui/material/Stack";

const TenantManagementView = ({handleClose, account}) => (
  <>
    <CustomViewField label='Номер' text={account?.TenantID} />
    <CustomViewField label='Наименование' text={account?.TenantName} />
    <CustomViewField label='Роль' text={account?.role} />
    <Grid item sx={{ width: '100%', mb: 2 }}>
      <Stack direction='row' spacing={1} alignItems='center'>
        {/*<Button*/}
        {/*  variant='contained'*/}
        {/*  onClick={() => setOpenForm('Update')}*/}
        {/*>*/}
        {/*  Редактировать*/}
        {/*</Button>*/}
        <Button
          variant='contained'
          color='primary'
          onClick={handleClose}
        >
          Закрыть
        </Button>
      </Stack>
    </Grid>
  </>
)

export default TenantManagementView;