// @mui
import { Container, Link, Typography } from '@mui/material'
// hooks
// sections
import { LoginForm } from '../../components/Forms/LoginForm'
import { useTranslation } from 'react-i18next'
import { StyledContent, StyledRoot } from '../../theme/standarts_styles'
import React from 'react'

export default function Login () {
  const { t } = useTranslation()
  return (
    <div className="ddddddd" style={{height: '100%', background: '#F6F9FC'}}>
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h3" gutterBottom align="center" mt={6} color="layout.element">
              {t('src.pages.Login.authorization')}
            </Typography>
            <LoginForm/>
          </StyledContent>
        </Container>
      </StyledRoot>
    </div>
  )
}
