import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Card, Grid, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import CustomTextField from "../../components/Fields/CustomTextField";
import CustomAutocomplete from "../../components/Fields/CustomAutocomplete";
import CustomDatepicker from "../../components/Fields/CustomDatepicker";
import {useState} from "react";
import {$authHost} from "../../http";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import {TitleStack} from "../../theme/standarts_styles";
import Container from "@mui/material/Container";
import {useNavigate} from "react-router-dom";

const MyAccountsAdd = ({handleClose}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: {isDirty, isSubmitSuccessful},
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await $authHost.post(`/invoice/create`, data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/my-accounts');
  };

  const handleReset = () => {
    reset();
  };

  return (
    <Container>
      <TitleStack>
        <Typography variant='h4'>Новый счет</Typography>
      </TitleStack>
      <Card sx={{mb: 2, p: 3}}>
        {loading && <LoadingSpinner/>}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CustomTextField
              name='AccountID'
              label='AccountID'
              required
            />
            <CustomTextField
              name='SubscriptionID'
              label='SubscriptionID'
              required
            />
            <CustomTextField
              name='AccountName'
              label='Наименование'
              required
            />
            <CustomTextField
              name='TotalAmount'
              label='Сумма'
              required
            />
            <CustomTextField
              name='state'
              label='Состояние'
            />
            <CustomAutocomplete
              name='payment'
              label='Способ оплаты'
              options={['1', '2', '3']}
            />
            <CustomAutocomplete
              name='CurrencyCode'
              label='Валюта'
              options={['USD', 'KZT', 'RUB']}
              required
            />
            <CustomDatepicker name='InvoiceDate' label='Дата' required/>
            <Stack direction='row' justifyContent='space-between'>
              <Stack direction='row' spacing={2}>
                <Button variant='contained' type='submit'>
                  Сохранить
                </Button>
                <Button
                  variant='text'
                  onClick={handleBack}
                >
                  Назад
                </Button>
              </Stack>
              <Button
                variant='text'
                onClick={handleReset}
              >
                Сбросить
              </Button>
            </Stack>
          </form>
        </FormProvider>
        {/*<BlockFormModal when={isDirty && !isSubmitSuccessful} />*/}
      </Card>
    </Container>
  )
}

export default MyAccountsAdd;