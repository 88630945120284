import {TitleStack} from "../theme/standarts_styles";
import {Card, Grid, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

const MainPage = () => {
	return (
		<Container sx={{ pb: 0 }}>
			<TitleStack>
				<Typography variant='h4' gutterBottom sx={{ mb: 0 }}>
					Ваш личный кабинет
				</Typography>
			</TitleStack>
			<Grid container direction='column' spacing={2}>
				<Grid item>
					<Card variant='outlined' sx={{p: 2}}>Информация о клиенте</Card>
				</Grid>
				<Grid item>
					<Stack direction='row' spacing={2}>
						<Card variant='outlined' sx={{p: 2, minWidth: '150px', textAlign: 'center'}}>Ваш баланс: 0</Card>
						<Card variant='outlined' sx={{p: 2, minWidth: '150px', textAlign: 'center'}}>К оплате: 0</Card>
					</Stack>
				</Grid>
				<Grid item>
					<Card variant='outlined' sx={{p: 2}}>Связаться с поддержкой</Card>
				</Grid>
				<Grid item>
					<Stack direction='row' spacing={2}>
						<Card variant='outlined' sx={{p: 2, minWidth: '150px', textAlign: 'center'}}>
							BIAnalytics<br/>
							0
						</Card>
						<Card variant='outlined' sx={{p: 2, minWidth: '150px', textAlign: 'center'}}>
							AI<br/>
							0
						</Card>
						<Card variant='outlined' sx={{p: 2, minWidth: '150px', textAlign: 'center'}}>
							Fort<br/>
							0
						</Card>
						<Card variant='outlined' sx={{p: 2, minWidth: '150px', textAlign: 'center'}}>
							UNI-Bi<br/>
							0
						</Card>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	)
}

export default MainPage