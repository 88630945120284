import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";

const CustomDatepicker = ({name, label, required, rules, inputProps, inputLabelProps, sx, ...props}) => {
  const {t} = useTranslation();

  const {
    control,
  } = useFormContext();

  if (required) {
    rules = {required: t('main.another.requiredField'), ...rules}
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
                 field: {onChange, value, ...field},
                 fieldState: {error},
               }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...field}
              label={`${label}${required || !!rules?.required ? ' *' : ''}`}
              control={control}
              value={value ? dayjs(value) : value}
              // value={value}
              onChange={(e) => {
                onChange(e);
              }}
              format='DD/MM/YYYY'
              slotProps={{
                textField: {
                  error: !!error,
                  helperText: error && (error?.message || 'Unknown error!'),
                  FormHelperTextProps: {
                    sx: {
                      position: 'absolute',
                      bottom: '-20px',
                    },
                  },
                  InputLabelProps: {
                    shrink: true,
                    ...inputLabelProps,
                  },
                  fullWidth: true,
                  size: 'small',
                  variant: 'outlined',
                  ...inputProps,
                },
              }}
              sx={{
                mb: 4,
                ...sx,
              }}
              {...props}
            />
          </LocalizationProvider>
        )
      }}
    />
  )
}

export default CustomDatepicker;
