import React from 'react'
import {useTranslation} from 'react-i18next'
import CustomMultiTextFieldAutocompleteForObject from '../../Fields/CustomMultiTextFieldAutocompleteForObject'
import CustomTextField from "../../Fields/CustomTextField";

const AccessGroupsFields = ({autocompleteOptions, selectedRow, baseGroups}) => {

  const {t} = useTranslation()

  return (
    <>
      <CustomTextField
        name='name'
        label={t('src.pages.AccessGroupsPage.groupName')}
        required
        disabled={baseGroups?.includes(selectedRow?.name) || false}
      />
      <CustomMultiTextFieldAutocompleteForObject
        multiple={true}
        name="roles"
        label={t('src.pages.AccessGroupsPage.roles')}
        options={autocompleteOptions}
        rules={{
          required: t('main.another.requiredField'),
        }}
        sx={{width: '100%', fontSize: '12px', mb: 3}}
        size="small"
        disabled={baseGroups?.includes(selectedRow?.name) || false}
      />
      <CustomTextField
        name='group_sso'
        label={t('src.pages.AccessGroupsPage.ssoGroup')}
        required
      />
    </>
  )
}

export default AccessGroupsFields