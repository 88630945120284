import React, {useState} from 'react'
import {
  Avatar,
  Box,
  Button,
  Divider,
  MenuItem,
  Popover,
  Stack,
  Typography
} from '@mui/material'
import {useAuth} from '../../hook/useAuth'
import {NavLink, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import useResponsive from '../../hook/useResponsive'
import {desiredLocations} from '../../constants'
import {useSelector} from 'react-redux'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function AccountPopover() {
  const {t} = useTranslation()
  const MENU_OPTIONS = [
    {
      label: t('src.layouts.dashboard.header.AccountPopover.munuOption1'),
      icon: 'eva:settings-2-fill',
      path: '/account',
    },
    {
      label: 'Управление тенантами',
      icon: 'eva:settings-2-fill',
      path: '/tenant-management',
    },
  ]
  const [open, setOpen] = useState(null)
  const isDesktop = useResponsive('up', 'lg')
  const {signOut} = useAuth()
  const location = useLocation()
  const currentLocation = desiredLocations.includes(location.pathname)

  const {
    UserLastName,
    UserName,
    Email,
  } = useSelector(state => state.authReducer)

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(false)
  }


  const handleLogout = () => {
    signOut()
    setOpen(false)
  }

  return (
    <>
      <Button variant="text" onClick={handleOpen}>
        <Stack spacing={1} direction='row' alignItems='center'>
          <Avatar
            src={''}
            alt='photoURL'
            sx={{
              width: '30px',
              height: '30px',
            }}
          />
          {isDesktop && (
            <Box>
              <Typography
                variant='subtitle1'
                sx={{
                  color: 'layout.element',
                  textAlign: 'start',
                }}
              >
                {UserName} {UserLastName.charAt(0)}.
              </Typography>
            </Box>
          )}
          <KeyboardArrowDownIcon
            fontSize='medium'
            sx={{
              color: 'layout.element',
            }}
          />
        </Stack>
      </Button>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        sx={{zIndex: '10000'}}
        PaperProps={{
          sx: {
            zIndex: 5001,
            backgroundColor: 'background.main',
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 200,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant="subtitle2" noWrap>
            {UserName} {UserLastName.charAt(0)}.
          </Typography>
          <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
            {Email}
          </Typography>
        </Box>

        <Divider sx={{borderStyle: 'dashed', color: 'divider'}}/>

        {/*more navigation*/}
        {!currentLocation &&
          <>
            <Stack sx={{p: 1}}>
              {MENU_OPTIONS.map((option, index) => (
                <NavLink to={option.path} style={{fontStyle: 'none', textDecoration: 'none', color: 'inherit'}}
                         onClick={handleClose} key={index}>
                  <MenuItem key={option.label}>
                    {option.label}
                  </MenuItem>
                </NavLink>
              ))}
            </Stack>
            <Divider sx={{borderStyle: 'dashed', color: 'divider'}}/>
          </>
        }

        <MenuItem onClick={handleLogout} sx={{m: 1}}>
          {t('src.layouts.dashboard.header.AccountPopover.exit', 'Выйти', 'ru')}
        </MenuItem>
      </Popover>
    </>
  )
}
