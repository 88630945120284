export const desiredLocations = [  // искомые локации
  '/primary-registration',
  '/new-eula',
  '/reset-password',
  '/change-password',
]
export const acceptFileType = [
  'image/jpeg',
  'image/png',
]
export const acceptFileTypeXL = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]
export const darkLogo = '/assets/icons/navbar/main_logo.png';
export const lightLogo = '/assets/icons/navbar/logo_main_white.png';

export const APP_BAR_MOBILE = 64
export const HEADER_MOBILE = 64
export const APP_BAR_DESKTOP = 70
export const HEADER_DESKTOP = 70
export const NAV_WIDTH = 280

export const NAV_WIDTH_CLOSE = 70

export const authState = {
  access_token: "sdfvdfvbdfbgrbrd446567jh56bv3wve",
  isInitAuth: true,
  isWarningModalOpen: false,
  userId: "sdfvdfvbdfbgrbrd446567jh56bv3wve",
  last_name: "aaaaa",
  email: "aaaaa@as.ru",
  startTime: null,
  name: "nnmnnnnn",
  roles: [{name: 'base'},
    {name: 'AccessManagement'},
    {name: 'WithoutRoles'}],
  groups: ['Администратор', 'Пользователь'],
  status: "Active",
  locked_flags: null,
  avatar: null,
  ssoLogin: false,
  ssoParams: {},
}