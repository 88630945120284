import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tasks: [],
}

const uuidSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    logOut () { return initialState },
    addTask (state, { payload }) { state.tasks.push(payload) },
    killTask (state, { payload }) { state.tasks = state.tasks.filter((task) => task.uuid !== payload) },
    updateStatus (state, { payload }) {
      const { task_id, progress, status } = payload
      state.tasks = state.tasks.map((task) =>
        task.uuid === task_id
          ? {
            ...task,
            progress,
            status,
          }
          : task,
      )
    },
  },
})

export const { actions: uuidActions, reducer: uuidReducer } = uuidSlice

export default uuidSlice.reducer
